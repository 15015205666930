import { Button, Container } from "@mui/material";
import React from "react";
import { IGameState } from "../models/game-state";
import GameStateManager from "../utils/game-state-manager";

interface GameSplashProps {
	gameState: IGameState;
}

const GameSplash = (props: GameSplashProps) => {
	return (
		<Container>
			<h1>The Myuzeeq Gaym</h1>
			<p>Would you like to play?</p>
			<Button
				variant="contained"
				color="success"
				onClick={GameStateManager.instance.startGame}
			>
				Start the game!
			</Button>
		</Container>
	);
};
export default GameSplash;
