import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import React from "react";
import { IGameState } from "../models/game-state";

interface LoopLoadingProps {
	gameState: IGameState;
}

const LoopLoading = (props: LoopLoadingProps) => {
	return (
		<Dialog open>
			<DialogTitle>Loading ...</DialogTitle>
			<DialogContent>
				<CircularProgress size={120} />
			</DialogContent>
		</Dialog>
	);
};
export default LoopLoading;
