import React from "react";
import {
	AppBar,
	Box,
	createTheme,
	CssBaseline,
	ThemeProvider,
	Toolbar,
	Typography,
} from "@mui/material";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./sites/Home";
import Tracks from "./sites/Tracks";

function App() {
	let theme = createTheme();

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box mb="72px">
					<Routes>
						<Route index element={<Home />} />
						<Route path="tracks" element={<Tracks />} />
					</Routes>
				</Box>
				<AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
					<Toolbar>
						<Typography sx={{ flexGrow: 1, textAlign: "center" }}>
							<b>The Myuzeeq Gaym</b> | development phase{" "}
							<b>{process.env.REACT_APP_DEVELOPMENT_PHASE}</b> |
							version <b>{process.env.REACT_APP_VERSION}</b> |
							environment <b>{process.env.NODE_ENV}</b> | authors:{" "}
							<b>{process.env.REACT_APP_AUTHORS}</b> |{" "}
							<Link to="/tracks">Options</Link>
						</Typography>
					</Toolbar>
				</AppBar>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
