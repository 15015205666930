import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	AdditionalCountdownInSeconds,
	countdownLengthsInSeconds,
	GameMode,
	gameModes,
	IGameState,
	IPlayer,
	PlayerColour,
	playerColours,
} from "../models/game-state";
import {
	TrackAudioLengthInSeconds,
	trackAudioLengths,
	trackDifficulties,
	TrackDifficulty,
	TrackTag,
	trackTags,
} from "../models/track";
import GameStateManager from "../utils/game-state-manager";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { mapRecord } from "../utils/helpers";
import { database } from "../database";
import SquareIcon from "@mui/icons-material/Square";
import { v4 as uuidv4 } from "uuid";

interface GameGetParamsProps {
	gameState: IGameState;
}

const GameGetParams = (props: GameGetParamsProps) => {
	const [players, setPlayers] = useState<IPlayer[]>([
		{ name: "", colour: "pink", playerGuid: uuidv4() },
		{ name: "", colour: "lightBlue", playerGuid: uuidv4() },
	]);
	const [category, setCategory] = useState<TrackTag>("movie");
	const [gameMode, setGameMode] = useState<GameMode>(
		database.defaultSettings.gameParameters.gameMode
	);
	const [trackAudioLength, setTrackAudioLength] =
		useState<TrackAudioLengthInSeconds>(
			database.defaultSettings.gameParameters.audioClipLength
		);
	const [additionalCountdown, setAdditionalCountdown] =
		useState<AdditionalCountdownInSeconds>(
			database.defaultSettings.gameParameters.additionalCountdown
		);
	const [minTrackDifficulty, setMinTrackDifficulty] = useState(
		database.defaultSettings.gameParameters.minTrackDifficulty
	);
	const [maxTrackDifficulty, setMaxTrackDifficulty] = useState(
		database.defaultSettings.gameParameters.maxTrackDifficulty
	);

	const [formValid, setFormValid] = useState(false);

	useEffect(() => {
		const isFormValid = players.every((p) => !!p.name);
		setFormValid(isFormValid);
	}, [players]);

	const handleNameChange = (name: string, idx: number) => {
		const newPlayers = [...players];
		newPlayers[idx].name = name;
		setPlayers(newPlayers);
	};

	const handleColourChange = (colour: PlayerColour, idx: number) => {
		const newPlayers = [...players];
		newPlayers[idx].colour = colour;
		setPlayers(newPlayers);
	};

	const handleDeletePlayer = (idx: number) => {
		const newPlayers = [...players];
		newPlayers.splice(idx, 1);
		setPlayers(newPlayers);
	};

	const handleAddPlayer = () => {
		const newPlayers = [
			...players,
			{ name: "", colour: "yellow", playerGuid: uuidv4() } as IPlayer,
		];
		setPlayers(newPlayers);
	};

	const handleStartGameClick = () =>
		GameStateManager.instance.inputGameParameters({
			gameMode,
			trackTags: [category],
			minTrackDifficulty,
			maxTrackDifficulty,
			audioClipLength: trackAudioLength,
			additionalCountdown: additionalCountdown,
			players: players as IPlayer[],
		});

	return (
		<Container>
			<h2>Before we start ...</h2>
			<Card elevation={4}>
				<CardContent>
					<Box pb={4}>
						<h3>Players</h3>
						<Stack spacing={2}>
							{players.map((p, idx) => (
								<Stack direction="row" spacing={2} key={idx}>
									<TextField
										fullWidth
										value={p.name}
										label={"Player " + (idx + 1)}
										onChange={(e) =>
											handleNameChange(
												e.target.value,
												idx
											)
										}
									/>
									<Box width={120}>
										<FormControl fullWidth>
											<InputLabel>Colour</InputLabel>
											<Select
												label="Colour"
												value={p.colour}
												onChange={(e) =>
													handleColourChange(
														e.target
															.value as PlayerColour,
														idx
													)
												}
											>
												{mapRecord(
													playerColours,
													(k, v, idx) => (
														<MenuItem
															key={idx}
															value={k}
														>
															<SquareIcon
																sx={{
																	color: k,
																}}
															/>{" "}
															{v}
														</MenuItem>
													)
												)}
											</Select>
										</FormControl>
									</Box>
									<IconButton
										onClick={() => handleDeletePlayer(idx)}
									>
										<DeleteIcon />
									</IconButton>
								</Stack>
							))}
							<Button
								variant="outlined"
								onClick={handleAddPlayer}
								color="primary"
							>
								<AddIcon /> Add Player
							</Button>
						</Stack>
					</Box>
					<Stack spacing={2} pb={4}>
						<h3>Game settings</h3>
						<Stack direction="row" spacing={2}>
							<FormControl fullWidth>
								<InputLabel>Category</InputLabel>
								<Select
									label="Category"
									value={category}
									onChange={(e) =>
										setCategory(e.target.value as TrackTag)
									}
								>
									{mapRecord(trackTags, (k, v, idx) => (
										<MenuItem key={idx} value={k}>
											{v}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel>Game mode</InputLabel>
								<Select
									label="Game mode"
									value={gameMode}
									onChange={(e) =>
										setGameMode(e.target.value as GameMode)
									}
								>
									{mapRecord(gameModes, (k, v, idx) => (
										<MenuItem key={idx} value={k}>
											{v}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
						<Stack direction="row" spacing={2}>
							<FormControl fullWidth>
								<InputLabel>Clip length</InputLabel>
								<Select
									label="Clip length"
									value={trackAudioLength}
									onChange={(e) =>
										setTrackAudioLength(
											parseInt(
												e.target.value as string,
												10
											) as TrackAudioLengthInSeconds
										)
									}
								>
									{mapRecord(
										trackAudioLengths,
										(k, v, idx) => (
											<MenuItem key={idx} value={k}>
												{v}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel>Additional countdown</InputLabel>
								<Select
									label="Additional countdown"
									value={additionalCountdown}
									onChange={(e) =>
										setAdditionalCountdown(
											e.target
												.value as AdditionalCountdownInSeconds
										)
									}
								>
									{mapRecord(
										countdownLengthsInSeconds,
										(k, v, idx) => (
											<MenuItem key={idx} value={k}>
												{v}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
						</Stack>
						<Stack direction="row" spacing={2}>
							<FormControl fullWidth>
								<InputLabel>
									Minimum track difficulty
								</InputLabel>
								<Select
									label="Minimum track difficulty"
									value={minTrackDifficulty}
									onChange={(e) =>
										setMinTrackDifficulty(
											e.target.value as TrackDifficulty
										)
									}
								>
									{mapRecord(
										trackDifficulties,
										(k, v, idx) => (
											<MenuItem key={idx} value={k}>
												{v}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel>
									Maximum track difficulty
								</InputLabel>
								<Select
									label="Maximum track difficulty"
									value={maxTrackDifficulty}
									onChange={(e) =>
										setMaxTrackDifficulty(
											e.target.value as TrackDifficulty
										)
									}
								>
									{mapRecord(
										trackDifficulties,
										(k, v, idx) => (
											<MenuItem key={idx} value={k}>
												{v}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
						</Stack>
					</Stack>
					<Typography align="right">
						<Button
							variant="contained"
							color="success"
							onClick={handleStartGameClick}
							disabled={!formValid}
						>
							Start the game!
						</Button>
					</Typography>
				</CardContent>
			</Card>
		</Container>
	);
};
export default GameGetParams;
