export const promiseTimeout = (ms: number) => {
	return new Promise<void>((resolve) => setTimeout(resolve, ms));
};

export const mapRecord = <K extends string | number | symbol, T, M>(
	r: Record<K, T>,
	cb: (key: K, value: T, index: number) => M
) => {
	const keys: K[] = Object.keys(r) as K[];
	return keys.map((k, idx) => cb(k, r[k], idx));
};

export const toMinutesAndSeconds = (seconds: number) => {
	return `${Math.floor(seconds / 60)}:${(Math.floor(seconds) % 60)
		.toString()
		.padStart(2, "0")}`;
};
