import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Stack,
	Typography,
} from "@mui/material";
import React from "react";
import { IGameState } from "../models/game-state";
import { trackTags } from "../models/track";
import GameStateManager from "../utils/game-state-manager";

interface LoopAnswerProps {
	gameState: IGameState;
}

const LoopAnswer = (props: LoopAnswerProps) => {
	const { gameState } = props;
	return (
		<Dialog open fullWidth maxWidth="lg">
			<DialogContent sx={{ p: 0 }}>
				<Stack direction="row" spacing={2}>
					{gameState.currentLoop.track && (
						<img
							src={gameState.currentLoop.track?.coverUrl}
							alt="cover"
							style={{ maxHeight: "85vh" }}
						/>
					)}
					<div>
						<Typography variant="h2">
							{gameState.currentLoop.track?.title}
						</Typography>
						<Typography variant="body1">
							Debut year:{" "}
							<b>{gameState.currentLoop.track?.debutYear}</b>
						</Typography>
						<Typography variant="body1">
							Tags:{" "}
							{gameState.currentLoop.track?.tags
								?.map((t) => <b>{trackTags[t]}</b>)
								.reduce(
									(prev, curr, idx) => (
										<>
											{prev}
											{idx > 0 ? <>,</> : <></>} {curr}
										</>
									),
									<></>
								)}
						</Typography>
						<DialogActions>
							<Button
								sx={{ align: "center" }}
								variant="contained"
								color="success"
								onClick={() =>
									GameStateManager.instance.inputScore(
										"correct"
									)
								}
							>
								Correct
							</Button>
							<Button
								sx={{ align: "center" }}
								variant="contained"
								color="error"
								onClick={() =>
									GameStateManager.instance.inputScore(
										"incorrect"
									)
								}
							>
								Incorrect / No Aswer
							</Button>
						</DialogActions>
					</div>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
export default LoopAnswer;
