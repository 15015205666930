import { Database } from "./models/database";

export const database: Database = {
	defaultSettings: {
		gameParameters: {
			gameMode: "9_3",
			trackTags: [],
			minTrackDifficulty: 1,
			maxTrackDifficulty: 8,
			audioClipLength: 60,
			additionalCountdown: 15,
			players: [],
		},
	},
	tracks: [
		{
			trackGuid: "32a0f7b1-9ed9-4c5d-938a-66464662612e",
			title: "13 Reasons Why",
			audioUrl: "audio/series/13_reasons_why.2017.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/13_reasons_why.2017.jpg",
			debutYear: 2017,
		},
		{
			trackGuid: "989732c2-c812-4136-bbb3-bc824562fb5f",
			title: "24",
			audioUrl: "audio/series/24.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 30,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 30,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 30,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/24.2001.jpg",
			debutYear: 2001,
		},
		{
			trackGuid: "0456f6d6-ec09-40ac-ba48-676a7926574d",
			title: "3rd Rock From the Sun",
			audioUrl: "audio/series/3rd_rock_from_the_sun.1996.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/3rd_rock_from_the_sun.1996.jpg",
			debutYear: 1996,
		},
		{
			trackGuid: "095d109f-c927-4f74-bccf-1c23172fb068",
			title: "After Life",
			audioUrl: "audio/series/after_life.2019.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/after_life.2019.jpg",
			debutYear: 2019,
		},
		{
			trackGuid: "3b89c6be-1e3e-4683-8a7b-d84237c428d1",
			title: "Alf",
			audioUrl: "audio/series/alf.1986.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/alf.1986.jpg",
			debutYear: 1986,
		},
		{
			trackGuid: "40cb71db-7b7a-46c9-ab05-1e0f05f56032",
			title: "'Allo 'Allo!",
			audioUrl: "audio/series/allo_allo.1982.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/allo_allo.1982.jpg",
			debutYear: 1982,
		},
		{
			trackGuid: "3dbfce75-058b-44a1-a1c1-605df2936d3c",
			title: "Arcane",
			audioUrl: "audio/series/arcane.2021.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 5,
			coverUrl: "covers/series/arcane.2021.jpg",
			debutYear: 2021,
		},
		{
			trackGuid: "1ef94fca-b6c4-420f-9e2a-cc27deec93b3",
			title: "Band of Brothers",
			audioUrl: "audio/series/band_of_brothers.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/band_of_brothers.2001.jpg",
			debutYear: 2001,
		},
		{
			trackGuid: "c3bc776b-68db-42be-91cd-f455f1ddaae5",
			title: "Barry",
			audioUrl: "audio/series/barry.2018.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/barry.2018.jpg",
			debutYear: 2018,
		},
		{
			trackGuid: "87cf20e4-5395-4bc3-b4d6-3d39d5ea3133",
			title: "Battlestar Galactica",
			audioUrl: "audio/series/battlestar_galactica.2004.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/battlestar_galactica.2004.jpg",
			debutYear: 2004,
		},
		{
			trackGuid: "4df81e56-00bc-493b-8808-90100250ef81",
			title: "Baywatch",
			audioUrl: "audio/series/baywawtch.1989.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/baywawtch.1989.jpg",
			debutYear: 1989,
		},
		{
			trackGuid: "4c239f19-3920-4e65-9f49-f77fe905f715",
			title: "Beavis And Butt-Head",
			audioUrl: "audio/series/beavis_and_butthead.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 8,
			coverUrl: "covers/series/beavis_and_butthead.1993.jpg",
			debutYear: 1993,
		},
		{
			trackGuid: "689f19b4-28e8-41d8-a417-3c98f74d7aa1",
			title: "Better Call Saul",
			audioUrl: "audio/series/better_call_saul.2015.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/better_call_saul.2015.jpg",
			debutYear: 2015,
		},
		{
			trackGuid: "338defd0-4f86-487e-8cec-6a42e339981e",
			title: "Beverly Hills, 90210",
			audioUrl: "audio/series/beverly_hills_90210.1990.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/beverly_hills_90210.1990.jpg",
			debutYear: 1990,
		},
		{
			trackGuid: "af61b8fe-c348-42ea-afe4-4f32bba536bd",
			title: "Black Mirror",
			audioUrl: "audio/series/black_mirror.2011.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/black_mirror.2011.jpg",
			debutYear: 2011,
		},
		{
			trackGuid: "8ec1dc54-5117-4b2b-ba27-4a3486400c2a",
			title: "Blackadder",
			audioUrl: "audio/series/blackadder.1982.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/blackadder.1982.jpg",
			debutYear: 1982,
		},
		{
			trackGuid: "a979b485-3c98-41a8-b4bb-4b33fd8a45e4",
			title: "BoJack Horseman",
			audioUrl: "audio/series/bojack_horseman.2014.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 3,
			coverUrl: "covers/series/bojack_horseman.2014.jpg",
			debutYear: 2014,
		},
		{
			trackGuid: "7bdae660-2eae-4e34-9637-c0ce0d927e2b",
			title: "Breaking Bad",
			audioUrl: "audio/series/breaking_bad.2008.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/breaking_bad.2008.jpg",
			debutYear: 2008,
		},
		{
			trackGuid: "037df293-9cb4-43d6-8159-74ad3910e27e",
			title: "Buffy The Vampire Slayer",
			audioUrl: "audio/series/buffy_the_vampire_slayer.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/buffy_the_vampire_slayer.1997.jpg",
			debutYear: 1997,
		},
		{
			trackGuid: "f9ceb545-e3c4-47f7-ad03-750c0e9110b0",
			title: "Charmed",
			audioUrl: "audio/series/charmed.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/charmed.1998.jpg",
			debutYear: 1998,
		},
		{
			trackGuid: "2abe90d9-49de-49e1-9998-6ed35292bda7",
			title: "Chernobyl",
			audioUrl: "audio/series/chernobyl.2019.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/chernobyl.2019.jpg",
			debutYear: 2019,
		},
		{
			trackGuid: "969ae457-419a-4fd9-abfb-c4e85cbdfb33",
			title: "Columbo",
			audioUrl: "audio/series/columbo.1971.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/columbo.1971.jpg",
			debutYear: 1971,
		},
		{
			trackGuid: "53231de4-5d45-4595-9885-383d595c0c8a",
			title: "Community",
			audioUrl: "audio/series/community.2009.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/community.2009.jpg",
			debutYear: 2009,
		},
		{
			trackGuid: "e4cd3c28-0620-433c-8807-7a6ac4d51438",
			title: "CSI Miami",
			audioUrl: "audio/series/csi_miami.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/csi_miami.2002.jpg",
			debutYear: 2002,
		},
		{
			trackGuid: "967d7511-8f28-4b98-beef-2d9a56daf91e",
			title: "Dahmer - Monster: The Jeffery Dahmer Story",
			audioUrl:
				"audio/series/dahmer_monster_the_jeffrey_dahmer_story.2022.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl:
				"covers/series/dahmer_monster_the_jeffrey_dahmer_story.2022.jpg",
			debutYear: 2022,
		},
		{
			trackGuid: "be445cb9-ac52-4f79-864a-5e6fb05fcb4f",
			title: "Desperate Housewives",
			audioUrl: "audio/series/desperate_housewives.2004.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/desperate_housewives.2004.jpg",
			debutYear: 2004,
		},
		{
			trackGuid: "20e5f91e-2e40-4ce7-967e-a16414b32c97",
			title: "Dexter",
			audioUrl: "audio/series/dexter.2006.mp3",
			audioClips: [
				{
					audioStartInSeconds: 4,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 4,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 4,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/dexter.2006.jpg",
			debutYear: 2006,
		},
		{
			trackGuid: "a07d9dfe-aaf3-49e7-97d5-4fa59d4eea9b",
			title: "ER",
			audioUrl: "audio/series/er.1994.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/er.1994.jpg",
			debutYear: 1994,
		},
		{
			trackGuid: "97abbcf0-65e1-418c-ba79-e16bd4e1b8ad",
			title: "Esmeralda",
			audioUrl: "audio/series/esmeralda.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/esmeralda.1997.jpg",
			debutYear: 1997,
		},
		{
			trackGuid: "be658dcf-df89-4eca-87bb-ad709ee5a557",
			title: "Family Guy",
			audioUrl: "audio/series/family_guy.1999.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 1,
			coverUrl: "covers/series/family_guy.1999.jpg",
			debutYear: 1999,
		},
		{
			trackGuid: "f6d4bd7b-63c1-4553-9c7b-72bfecefb144",
			title: "Family Matters",
			audioUrl: "audio/series/family_matters.1989.mp3",
			audioClips: [
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/family_matters.1989.jpg",
			debutYear: 1989,
		},
		{
			trackGuid: "d8cc7973-217d-471b-9d5a-102f0580462f",
			title: "Fargo",
			audioUrl: "audio/series/fargo.2014.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/fargo.2014.jpg",
			debutYear: 2014,
		},
		{
			trackGuid: "4f1b06af-6886-4a43-ab93-fcb0c8e1ee8f",
			title: "Fawlty Towers",
			audioUrl: "audio/series/fawlty_towers.1975.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/fawlty_towers.1975.jpg",
			debutYear: 1975,
		},
		{
			trackGuid: "5d26ab8a-b4b7-4ace-8523-f12f139d50b9",
			title: "Firefly",
			audioUrl: "audio/series/firefly.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/firefly.2002.jpg",
			debutYear: 2002,
		},
		{
			trackGuid: "d11fa23a-7cdd-484e-b0f9-b8a3a70725a0",
			title: "For All Mankind",
			audioUrl: "audio/series/for_all_mankind.2019.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/for_all_mankind.2019.jpg",
			debutYear: 2019,
		},
		{
			trackGuid: "cb7db463-a02c-464f-8bea-126c2e87972b",
			title: "Foundation",
			audioUrl: "audio/series/foundation.2021.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/foundation.2021.jpg",
			debutYear: 2021,
		},
		{
			trackGuid: "06e6753b-7390-405e-9635-bf46c08dcfda",
			title: "Freaks and Greeks",
			audioUrl: "audio/series/freaks_and_geeks.1999.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/freaks_and_geeks.1999.jpg",
			debutYear: 1999,
		},
		{
			trackGuid: "4d4e2c3e-3507-44a4-a737-066bcffe1523",
			title: "Friends",
			audioUrl: "audio/series/friends.1994.mp3",
			audioClips: [
				{
					audioStartInSeconds: 2,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 2,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 2,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/friends.1994.jpg",
			debutYear: 1994,
		},
		{
			trackGuid: "b5f9df39-d39c-45d5-a399-094873c0b774",
			title: "Full House",
			audioUrl: "audio/series/full_house.1995.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/full_house.1995.jpg",
			debutYear: 1995,
		},
		{
			trackGuid: "6a818244-b8be-4283-9cee-4cadefc09132",
			title: "Futurama",
			audioUrl: "audio/series/futurama.1999.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 3,
			coverUrl: "covers/series/futurama.1999.jpg",
			debutYear: 1999,
		},
		{
			trackGuid: "aa74eb50-1b27-43b3-886a-aea2f47b52d2",
			title: "Game of Thrones",
			audioUrl: "audio/series/game_of_thrones.2011.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/game_of_thrones.2011.jpg",
			debutYear: 2011,
		},
		{
			trackGuid: "9eccb050-d1df-4447-a187-6f78fe3ce7b3",
			title: "Gilmore Girls",
			audioUrl: "audio/series/gilmore_girls.2000.mp3",
			audioClips: [
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/gilmore_girls.2000.jpg",
			debutYear: 2000,
		},
		{
			trackGuid: "203d7d61-2c72-4ea8-bd0a-f6c47389d26d",
			title: "Good Times",
			audioUrl: "audio/series/good_times.1974.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/good_times.1974.jpg",
			debutYear: 1974,
		},
		{
			trackGuid: "9d4d520b-0931-4bf9-88d6-029765f67309",
			title: "Gossip Girl",
			audioUrl: "audio/series/gossip_girl.2007.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/gossip_girl.2007.jpg",
			debutYear: 2007,
		},
		{
			trackGuid: "74d5170b-4266-4c2b-ad3f-654817175f6c",
			title: "Home Improvement",
			audioUrl: "audio/series/home_improvement.1991.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/home_improvement.1991.jpg",
			debutYear: 1991,
		},
		{
			trackGuid: "7b0c4274-2e60-4354-a795-c0a44227935f",
			title: "House",
			audioUrl: "audio/series/house.2004.mp3",
			audioClips: [
				{
					audioStartInSeconds: 6,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 6,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 6,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/house.2004.jpg",
			debutYear: 2004,
		},
		{
			trackGuid: "c315fc2c-0a82-4450-8d53-efe9725e8c86",
			title: "How I Met Your Mother",
			audioUrl: "audio/series/how_i_met_your_mother.2005.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/how_i_met_your_mother.2005.jpg",
			debutYear: 2005,
		},
		{
			trackGuid: "84758b10-5913-4616-901c-598066fa61e2",
			title: "How It's Made",
			audioUrl: "audio/series/how_its_made.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/how_its_made.2001.jpg",
			debutYear: 2001,
		},
		{
			trackGuid: "90b5d6a3-1cf6-4c08-b9a1-870b159a0c5f",
			title: "I Love Lucy",
			audioUrl: "audio/series/i_love_lucy.1951.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/i_love_lucy.1951.jpg",
			debutYear: 1951,
		},
		{
			trackGuid: "88a63e23-8f8d-4d5a-b100-62628da63d32",
			title: "It's Always Sunny in Philadelphia",
			audioUrl: "audio/series/its_always_sunny_in_philadelphia.2005.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/its_always_sunny_in_philadelphia.2005.jpg",
			debutYear: 2005,
		},
		{
			trackGuid: "6f01d09b-71ab-4e71-9383-6e81b6b7525b",
			title: "Knight Rider",
			audioUrl: "audio/series/knight_rider.1982.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/knight_rider.1982.jpg",
			debutYear: 1982,
		},
		{
			trackGuid: "1b3f1de3-b424-4799-bc73-3eafa5aed264",
			title: "MacGyver",
			audioUrl: "audio/series/macgyver.1985.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/macgyver.1985.jpg",
			debutYear: 1985,
		},
		{
			trackGuid: "9f9cccb7-eb04-4d6c-b5d3-4a7a69bca5c1",
			title: "Married... with Children",
			audioUrl: "audio/series/married_with_children.1987.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/married_with_children.1987.jpg",
			debutYear: 1987,
		},
		{
			trackGuid: "0ed4e5c9-7b21-4c91-9412-d4fd99a5c3fb",
			title: "Metalocalypse",
			audioUrl: "audio/series/metalocalypse.2006.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 8,
			coverUrl: "covers/series/metalocalypse.2006.jpg",
			debutYear: 2006,
		},
		{
			trackGuid: "c303218d-dace-47fe-9a16-e7ee73a04972",
			title: "Miami Vice",
			audioUrl: "audio/series/miami_vice.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/miami_vice.1984.jpg",
			debutYear: 1984,
		},
		{
			trackGuid: "3c0bd295-bbe9-4c34-ae4d-16d64cc7655e",
			title: "Money Heist",
			audioUrl: "audio/series/money_heist.2017.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/money_heist.2017.jpg",
			debutYear: 2017,
		},
		{
			trackGuid: "35ab92a1-6126-429e-92b8-d50ef3d937a8",
			title: "Monty Python's Flying Circus",
			audioUrl: "audio/series/monty_pythons_flying_circus.1969.mp3",
			audioClips: [
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/monty_pythons_flying_circus.1969.jpg",
			debutYear: 1969,
		},
		{
			trackGuid: "6ef46824-3b69-40f5-a494-d4834d7a1ed2",
			title: "Mr Bean",
			audioUrl: "audio/series/mr_bean.1990.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/mr_bean.1990.jpg",
			debutYear: 1990,
		},
		{
			trackGuid: "67a96b75-f4c8-4c82-99cc-212f80adcb55",
			title: "Mrs Wilson",
			audioUrl: "audio/series/mrs_wilson.2018.mp3",
			audioClips: [
				{
					audioStartInSeconds: 25,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 25,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 25,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/mrs_wilson.2018.jpg",
			debutYear: 2018,
		},
		{
			trackGuid: "37587e04-f8dc-445c-8a70-845ecf6ae749",
			title: "Murder, She Wrote",
			audioUrl: "audio/series/murder_she_wrote.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/murder_she_wrote.1984.jpg",
			debutYear: 1984,
		},
		{
			trackGuid: "7e4a8c56-9fad-4b3b-b191-fb0f9fdaab41",
			title: "Mythbusters",
			audioUrl: "audio/series/mythbusters.2003.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/mythbusters.2003.jpg",
			debutYear: 2003,
		},
		{
			trackGuid: "19fcfba8-f648-446d-9a1a-223686de88b0",
			title: "Only Fools and Horses",
			audioUrl: "audio/series/only_fools_and_horses.1981.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/only_fools_and_horses.1981.jpg",
			debutYear: 1981,
		},
		{
			trackGuid: "959d3df2-6424-4ae6-8d44-f8eddf25393b",
			title: "Ozark",
			audioUrl: "audio/series/ozark.2017.mp3",
			audioClips: [
				{
					audioStartInSeconds: 2,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 2,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 2,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/ozark.2017.jpg",
			debutYear: 2017,
		},
		{
			trackGuid: "b4e4e979-e8f1-4786-9f55-6a95bf6c0eeb",
			title: "Parks and Recreation",
			audioUrl: "audio/series/parks_and_recreation.2009.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/parks_and_recreation.2009.jpg",
			debutYear: 2009,
		},
		{
			trackGuid: "d2acea95-1843-4896-a769-3c96a94eece9",
			title: "Peaky Blinders",
			audioUrl: "audio/series/peaky_blinders.2013.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/peaky_blinders.2013.jpg",
			debutYear: 2013,
		},
		{
			trackGuid: "975765f1-e259-4f73-b040-c3c628e1f883",
			title: "Resident Alien",
			audioUrl: "audio/series/resident_alien.2021.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/resident_alien.2021.jpg",
			debutYear: 2021,
		},
		{
			trackGuid: "b7bc9198-09a2-46da-9ccb-f94df9160393",
			title: "Rick and Morty",
			audioUrl: "audio/series/rick_and_morty.2013.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 2,
			coverUrl: "covers/series/rick_and_morty.2013.jpg",
			debutYear: 2013,
		},
		{
			trackGuid: "f3bb32b5-c0df-4c51-ba45-39ed1ad2d493",
			title: "Rome",
			audioUrl: "audio/series/rome.2005.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/rome.2005.jpg",
			debutYear: 2005,
		},
		{
			trackGuid: "1cc56a54-f3c7-4c26-8797-e7c707afb116",
			title: "Roseanne",
			audioUrl: "audio/series/roseanne.1988.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/roseanne.1988.jpg",
			debutYear: 1988,
		},
		{
			trackGuid: "5418bb75-3572-4538-b57b-3995cc056dd2",
			title: "Seinfeld",
			audioUrl: "audio/series/seinfeld.1989.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/seinfeld.1989.jpg",
			debutYear: 1989,
		},
		{
			trackGuid: "bd04cc1b-065f-4eac-91bf-74bdde6d447a",
			title: "Sex and The City",
			audioUrl: "audio/series/sex_and_the_city.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/sex_and_the_city.1998.jpg",
			debutYear: 1998,
		},
		{
			trackGuid: "5f522786-d23a-4ea7-9a45-57ab6eade4e4",
			title: "Sherlock",
			audioUrl: "audio/series/sherlock.2010.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/sherlock.2010.jpg",
			debutYear: 2010,
		},
		{
			trackGuid: "45ba2988-8e6e-43ec-a50e-d7cb47df7057",
			title: "Silicon Valley",
			audioUrl: "audio/series/silicon_valley.2014.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/silicon_valley.2014.jpg",
			debutYear: 2014,
		},
		{
			trackGuid: "d9c69739-e61b-4be6-82bd-aefc6db43ada",
			title: "Solar Opposites",
			audioUrl: "audio/series/solar_opposites.2020.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 8,
			coverUrl: "covers/series/solar_opposites.2020.jpg",
			debutYear: 2020,
		},
		{
			trackGuid: "d0dfcd7a-b0b2-4a83-a5c6-02f01edf0c7a",
			title: "South Park",
			audioUrl: "audio/series/south_park.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 1,
			coverUrl: "covers/series/south_park.1997.jpg",
			debutYear: 1997,
		},
		{
			trackGuid: "9c2b5ac7-674c-4f83-94b6-1d9ba38ed5ac",
			title: "Star Trek",
			audioUrl: "audio/series/star_trek.1966.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/star_trek.1966.jpg",
			debutYear: 1966,
		},
		{
			trackGuid: "c1e56a7e-4899-4370-9040-a4613e8c329f",
			title: "Star Trek: The Next Generation",
			audioUrl: "audio/series/star_trek_the_next_generation.1987.mp3",
			audioClips: [
				{
					audioStartInSeconds: 40,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 40,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 40,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/star_trek_the_next_generation.1987.jpg",
			debutYear: 1987,
		},
		{
			trackGuid: "c50fdac1-ef02-4e67-8e65-8e43ef86b2d3",
			title: "Stargate SG-1",
			audioUrl: "audio/series/stargate_sg_1.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/stargate_sg_1.1997.jpg",
			debutYear: 1997,
		},
		{
			trackGuid: "232437f9-d4a2-4d75-87db-a9346693658f",
			title: "Step By Step",
			audioUrl: "audio/series/step_by_step.1991.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/step_by_step.1991.jpg",
			debutYear: 1991,
		},
		{
			trackGuid: "34e6a12d-c827-4c67-b2ec-2ad9d0e076e9",
			title: "Stranger Things",
			audioUrl: "audio/series/stranger_things.2016.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/stranger_things.2016.jpg",
			debutYear: 2016,
		},
		{
			trackGuid: "39b64275-e47a-46a7-be08-cc78cbd2d07f",
			title: "Survivor",
			audioUrl: "audio/series/survivor.2000.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/survivor.2000.jpg",
			debutYear: 2000,
		},
		{
			trackGuid: "f492d95f-8e0c-4170-97a3-5ac2e381aeb5",
			title: "Taxi",
			audioUrl: "audio/series/taxi.1978.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/taxi.1978.jpg",
			debutYear: 1978,
		},
		{
			trackGuid: "0d338c16-567b-448b-bc41-24a3a5998d1c",
			title: "That 70's Show",
			audioUrl: "audio/series/that_70s_show.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/that_70s_show.1998.jpg",
			debutYear: 1998,
		},
		{
			trackGuid: "4196b64b-0bb3-4808-bc85-734a1d61a5f6",
			title: "The Expanse",
			audioUrl: "audio/series/the_expanse.2015.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/the_expanse.2015.jpg",
			debutYear: 2015,
		},
		{
			trackGuid: "83a02153-8e9e-4ec4-af8a-0148f6ea571b",
			title: "The Flintstones",
			audioUrl: "audio/series/the_flintstones.1960.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 1,
			coverUrl: "covers/series/the_flintstones.1960.jpg",
			debutYear: 1960,
		},
		{
			trackGuid: "34c96ed9-744a-4451-b37d-33c38a5b43c0",
			title: "The Fresh Prince of Bel Air",
			audioUrl: "audio/series/the_fresh_prince_of_bel_air.1990.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_fresh_prince_of_bel_air.1990.jpg",
			debutYear: 1990,
		},
		{
			trackGuid: "4b0ecb77-d58b-4a9e-8056-4d197bd121e5",
			title: "The Grand Tour",
			audioUrl: "audio/series/the_grand_tour.2016.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/the_grand_tour.2016.jpg",
			debutYear: 2016,
		},
		{
			trackGuid: "143adc58-73f2-4c5b-aea7-f31012fa1712",
			title: "The IT Crowd",
			audioUrl: "audio/series/the_it_crowd.2006.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/the_it_crowd.2006.jpg",
			debutYear: 2006,
		},
		{
			trackGuid: "89a74d13-e871-4126-83df-454724bee25f",
			title: "The Muppet Show",
			audioUrl: "audio/series/the_muppet_show.1976.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_muppet_show.1976.jpg",
			debutYear: 1976,
		},
		{
			trackGuid: "97228470-658d-4699-93df-04faaa8c0fb5",
			title: "The Nanny",
			audioUrl: "audio/series/the_nanny.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_nanny.1993.jpg",
			debutYear: 1993,
		},
		{
			trackGuid: "4894ee45-9056-451d-a04c-b201b0340046",
			title: "The Office",
			audioUrl: "audio/series/the_office.2005.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_office.2005.jpg",
			debutYear: 2005,
		},
		{
			trackGuid: "560d2135-f506-4333-9def-4f0b0a77ccce",
			title: "The Simpsons",
			audioUrl: "audio/series/the_simpsons.1989.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "animated"],
			difficulty: 1,
			coverUrl: "covers/series/the_simpsons.1989.jpg",
			debutYear: 1989,
		},
		{
			trackGuid: "87ec27ca-fbf0-4888-a097-4cc32ac41620",
			title: "The Sopranos",
			audioUrl: "audio/series/the_sopranos.1999.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_sopranos.1999.jpg",
			debutYear: 1999,
		},
		{
			trackGuid: "5b2f2776-1b71-42ab-abc7-a3063e86a7b7",
			title: "The Twilight Zone",
			audioUrl: "audio/series/the_twilight_zone.1959.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_twilight_zone.1959.jpg",
			debutYear: 1959,
		},
		{
			trackGuid: "9580e69c-33b9-42f6-a280-320a2605fa50",
			title: "The Walking Dead",
			audioUrl: "audio/series/the_walking_dead.2010.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_walking_dead.2010.jpg",
			debutYear: 2010,
		},
		{
			trackGuid: "7b7157f6-677d-42ab-ba3d-a7d21fd18350",
			title: "The Wire",
			audioUrl: "audio/series/the_wire.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/the_wire.2002.jpg",
			debutYear: 2002,
		},
		{
			trackGuid: "3e5a486a-5c68-4af5-bc22-b3851aa6b8ef",
			title: "The Witcher",
			audioUrl: "audio/series/the_witcher.2019.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/the_witcher.2019.jpg",
			debutYear: 2019,
		},
		{
			trackGuid: "34ddc0e4-7966-48fc-b462-a763e2aa4a30",
			title: "The X-Files",
			audioUrl: "audio/series/the_x_files.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/the_x_files.1993.jpg",
			debutYear: 1993,
		},
		{
			trackGuid: "34837fe5-33b1-419c-81e6-21bd21aba776",
			title: "Top Gear",
			audioUrl: "audio/series/top_gear.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/top_gear.2002.jpg",
			debutYear: 2002,
		},
		{
			trackGuid: "b5974256-59e0-411c-9c58-eaca0bc3a176",
			title: "Trailer Park Boys",
			audioUrl: "audio/series/trailer_park_boys.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/trailer_park_boys.2001.jpg",
			debutYear: 2001,
		},
		{
			trackGuid: "bf8d4cdd-dbf8-4601-acec-3d15087e62d0",
			title: "Twin Peaks",
			audioUrl: "audio/series/twin_peaks.1990.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 3,
			coverUrl: "covers/series/twin_peaks.1990.jpg",
			debutYear: 1990,
		},
		{
			trackGuid: "67755cb1-d4ab-47ca-af5d-3f715d9878ef",
			title: "Two and a Half Men",
			audioUrl: "audio/series/two_and_a_half_men.2003.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/two_and_a_half_men.2003.jpg",
			debutYear: 2003,
		},
		{
			trackGuid: "dd5c9f52-fd8e-4e96-820f-a8f229eb2c9d",
			title: "Vikings",
			audioUrl: "audio/series/vikings.2013.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 2,
			coverUrl: "covers/series/vikings.2013.jpg",
			debutYear: 2013,
		},
		{
			trackGuid: "15e75e43-7fde-4e5f-b8f0-e9c78a54d539",
			title: "Will & Grace",
			audioUrl: "audio/series/will_grace.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 5,
			coverUrl: "covers/series/will_grace.1998.jpg",
			debutYear: 1998,
		},
		{
			trackGuid: "aa4da4ea-30cc-400a-b2ef-3bc99994ed83",
			title: "Xena: Warrior Princess",
			audioUrl: "audio/series/xena_warrior_princess.1995.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/xena_warrior_princess.1995.jpg",
			debutYear: 1995,
		},
		{
			trackGuid: "7c14af60-5d6d-4adf-b1a5-8fb19a777fdb",
			title: "Yes Minister",
			audioUrl: "audio/series/yes_minister.1980.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 8,
			coverUrl: "covers/series/yes_minister.1980.jpg",
			debutYear: 1980,
		},
		{
			trackGuid: "b4367c80-b369-410a-baf6-c691bbcf3da9",
			title: "You Rang, M'Lord?",
			audioUrl: "audio/series/you_rang_mlord.1988.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["series", "live_action"],
			difficulty: 1,
			coverUrl: "covers/series/you_rang_mlord.1988.jpg",
			debutYear: 1988,
		},
		{
			trackGuid: "4f3cbf72-1693-49ec-b6d2-87d2c854ea69",
			title: "8 Mile",
			audioUrl: "audio/movies/8_Mile.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 31,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 31,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 31,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/8_Mile.2002.jpg",
			debutYear: 2002,
			externalIds: [
				{ service: "imdb", id: "tt0298203" },
				{ service: "themoviedb", id: "65-8-mile" },
			],
		},
		{
			trackGuid: "50582fd7-7768-45bd-a455-4c4f9b331df5",
			title: "12 Monkeys",
			audioUrl: "audio/movies/12_Monkeys.1995.mp3",
			audioClips: [
				{
					audioStartInSeconds: 3.5,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 3.5,
					audioLengthInSeconds: 45,
				},

				{
					audioStartInSeconds: 3.5,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/12_Monkeys.1995.jpg",
			debutYear: 1995,
			externalIds: [
				{ service: "imdb", id: "tt0114746" },
				{ service: "themoviedb", id: "63-twelve-monkeys" },
			],
		},
		{
			trackGuid: "31b63676-fa5f-4414-b8ff-c0bf1eb44712",
			title: "300",
			audioUrl: "audio/movies/300.2006.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "comics", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/300.2006.jpg",
			debutYear: 2006,
			externalIds: [
				{ service: "imdb", id: "tt0416449" },
				{ service: "themoviedb", id: "1271-300" },
			],
		},
		{
			trackGuid: "950dc405-6fa0-4cb2-ae13-27d2ce04a402",
			title: "1492: Conquest of Paradise",
			audioUrl: "audio/movies/1492_Conquest_of_Paradise.1992.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/1492_Conquest_of_Paradise.1992.jpg",
			debutYear: 1992,
			externalIds: [
				{ service: "imdb", id: "tt0103594" },
				{ service: "themoviedb", id: "1492-1492-conquest-of-paradise" },
			],
		},
		{
			trackGuid: "7b72b239-ff69-4625-a332-2bec46d04a88",
			title: "2001: A Space Odyssey",
			audioUrl: "audio/movies/2001_A_Space_Odyssey.1968.mp3",
			audioClips: [
				{
					audioStartInSeconds: 195,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 195,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 195,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/2001_A_Space_Odyssey.1968.jpg",
			debutYear: 1968,
			externalIds: [
				{ service: "imdb", id: "tt0062622" },
				{ service: "themoviedb", id: "62-2001-a-space-odyssey" },
			],
		},
		{
			trackGuid: "b0777ed1-f882-4059-b297-76cb286dee2f",
			title: "A Beautiful Mind",
			audioUrl: "audio/movies/A_Beautiful_Mind.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 31,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 31,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 31,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/A_Beautiful_Mind.2001.jpg",
			debutYear: 2001,
			externalIds: [
				{ service: "imdb", id: "tt0268978" },
				{ service: "themoviedb", id: "453-a-beautiful-mind" },
			],
		},
		{
			trackGuid: "e0459ea2-9d70-4951-a3c6-12e83d5ac685",
			title: "A Clockwork Orange",
			audioUrl: "audio/movies/A_Clockwork_Orange.1971.mp3",
			audioClips: [
				{
					audioStartInSeconds: 22,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 22,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 22,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/A_Clockwork_Orange.1971.jpg",
			debutYear: 1971,
			externalIds: [
				{ service: "imdb", id: "tt0066921" },
				{ service: "themoviedb", id: "185-a-clockwork-orange" },
			],
		},
		{
			trackGuid: "58be094d-04f3-4345-8273-60beace6e42c",
			title: "Akira",
			audioUrl: "audio/movies/Akira.1988.mp3",
			audioClips: [
				{
					audioStartInSeconds: 82,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 82,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 82,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 8,
			coverUrl: "covers/movies/Akira.1988.jpg",
			debutYear: 1988,
			externalIds: [
				{ service: "imdb", id: "tt0094625" },
				{ service: "themoviedb", id: "149-akira" },
			],
		},
		{
			trackGuid: "b1f384b7-3b5f-4621-84f0-c19176008288",
			title: "Ali G Indahouse",
			audioUrl: "audio/movies/Ali_G_Indahouse.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Ali_G_Indahouse.2002.jpg",
			debutYear: 2002,
			externalIds: [
				{ service: "imdb", id: "tt0284837" },
				{ service: "themoviedb", id: "9298-ali-g-indahouse" },
			],
		},
		{
			trackGuid: "e6233359-0783-4d04-bc6b-7f8c80db8cf6",
			title: "Alien",
			audioUrl: "audio/movies/Alien.1979.mp3",
			audioClips: [
				{
					audioStartInSeconds: 180,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 180,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 180,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Alien.1979.jpg",
			debutYear: 1979,
			externalIds: [
				{ service: "imdb", id: "tt0078748" },
				{ service: "themoviedb", id: "348-alien" },
			],
		},
		{
			trackGuid: "f58028c2-548a-404e-83ee-29a99451cb56",
			title: "American Beauty",
			audioUrl: "audio/movies/American_Beauty.1999.mp3",
			audioClips: [
				{
					audioStartInSeconds: 11,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 11,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 11,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/American_Beauty.1999.jpg",
			debutYear: 1999,
			externalIds: [
				{ service: "imdb", id: "tt0169547" },
				{ service: "themoviedb", id: "14-american-beauty" },
			],
		},
		{
			trackGuid: "ba62506f-d74f-46e0-b374-d0832c126488",
			title: "American Psycho",
			audioUrl: "audio/movies/American_Psycho.2000.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/American_Psycho.2000.jpg",
			debutYear: 2000,
			externalIds: [
				{ service: "imdb", id: "tt0144084" },
				{ service: "themoviedb", id: "1359-american-psycho" },
			],
		},
		{
			trackGuid: "a693e1cf-cd0d-47cc-997c-217416e2bfcd",
			title: "Apollo 13",
			audioUrl: "audio/movies/Apollo_13.1995.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Apollo_13.1995.jpg",
			debutYear: 1995,
			externalIds: [
				{ service: "imdb", id: "tt0112384" },
				{ service: "themoviedb", id: "568-apollo-13" },
			],
		},
		{
			trackGuid: "85dcfc08-4fc6-4bd8-838d-96f58a56b1d4",
			title: "Armageddon",
			audioUrl: "audio/movies/Armageddon.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 69,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 69,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 69,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Armageddon.1998.jpg",
			debutYear: 1998,
			externalIds: [
				{ service: "imdb", id: "tt0120591" },
				{ service: "themoviedb", id: "95-armageddon" },
			],
		},
		{
			trackGuid: "2ea882a0-a896-4862-9af9-968dbaeffdbe",
			title: "Austin Powers: International Man of Mystery",
			audioUrl:
				"audio/movies/Austin_Powers_International_Man_of_Mistery.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl:
				"covers/movies/Austin_Powers_International_Man_of_Mistery.1997.jpg",
			debutYear: 1997,
			externalIds: [
				{ service: "imdb", id: "tt0118655" },
				{
					service: "themoviedb",
					id: "816-austin-powers-international-man-of-mystery",
				},
			],
		},
		{
			trackGuid: "ec1cc7c6-6f2a-46d6-9cca-fb59399a620f",
			title: "Avatar",
			audioUrl: "audio/movies/Avatar.2009.mp3",
			audioClips: [
				{
					audioStartInSeconds: 18,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 18,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 18,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Avatar.2009.jpg",
			debutYear: 2009,
			externalIds: [
				{ service: "imdb", id: "tt0499549" },
				{ service: "themoviedb", id: "19995-avatar" },
			],
		},
		{
			trackGuid: "3c91e7d9-d2e0-4504-a496-277071e2484e",
			title: "Back to the Future",
			audioUrl: "audio/movies/Back_to_the_Future.1985.mp3",
			audioClips: [
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 3,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Back_to_the_Future.1985.jpg",
			debutYear: 1995,
			externalIds: [
				{ service: "imdb", id: "tt0088763" },
				{ service: "themoviedb", id: "105-back-to-the-future" },
			],
		},
		{
			trackGuid: "c98cfae7-6fb4-42e3-ab10-7c48288088bc",
			title: "Batman",
			audioUrl: "audio/movies/Batman.1989.mp3",
			audioClips: [
				{
					audioStartInSeconds: 28,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 28,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 28,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action", "comics"],
			difficulty: 3,
			coverUrl: "covers/movies/Batman.1989.jpg",
			debutYear: 1989,
			externalIds: [
				{ service: "imdb", id: "tt0096895" },
				{ service: "themoviedb", id: "268-batman" },
			],
		},
		{
			trackGuid: "cf548a04-6d9f-40a4-b1f7-69e0929514ec",
			title: "Bettlejuice",
			audioUrl: "audio/movies/Bettlejuice.1988.mp3",
			audioClips: [
				{
					audioStartInSeconds: 109,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 109,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 109,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Bettlejuice.1988.jpg",
			debutYear: 1988,
			externalIds: [
				{ service: "imdb", id: "tt0094721" },
				{ service: "themoviedb", id: "4011-beetlejuice" },
			],
		},
		{
			trackGuid: "38531379-9b96-4ad3-900e-ae773dd99bb8",
			title: "Beverly Hills Cop",
			audioUrl: "audio/movies/Beverly_Hills_Cop.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Beverly_Hills_Cop.1984.jpg",
			debutYear: 1984,
			externalIds: [
				{ service: "imdb", id: "tt0086960" },
				{ service: "themoviedb", id: "90-beverly-hills-cop" },
			],
		},
		{
			trackGuid: "28838f42-640a-419f-9682-476b428d238c",
			title: "Blade Runner",
			audioUrl: "audio/movies/Blade_Runner.1982.mp3",
			audioClips: [
				{
					audioStartInSeconds: 87,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 87,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 87,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Blade_Runner.1982.jpg",
			debutYear: 1982,
			externalIds: [
				{ service: "imdb", id: "tt0083658" },
				{ service: "themoviedb", id: "78-blade-runner" },
			],
		},
		{
			trackGuid: "e47b84da-1025-4eb7-888d-160b989940c4",
			title: "Braveheart",
			audioUrl: "audio/movies/Braveheart.1995.mp3",
			audioClips: [
				{
					audioStartInSeconds: 27,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 27,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 27,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Braveheart.1995.jpg",
			debutYear: 1995,
			externalIds: [
				{ service: "imdb", id: "tt0112573" },
				{ service: "themoviedb", id: "197-braveheart" },
			],
		},
		{
			trackGuid: "e86aa2ff-a07a-4acc-b9ae-f3ffc0b4928b",
			title: "Brazil",
			audioUrl: "audio/movies/Brazil.1985.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Brazil.1985.jpg",
			debutYear: 1985,
			externalIds: [
				{ service: "imdb", id: "tt0088846" },
				{ service: "themoviedb", id: "68-brazil" },
			],
		},
		{
			trackGuid: "fe3a7f3c-1e2e-4c62-83c6-d992d9016534",
			title: "Casino Royale",
			audioUrl: "audio/movies/Casino_Royale.2006.mp3",
			audioClips: [
				{
					audioStartInSeconds: 35,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 25,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 25,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Casino_Royale.2006.jpg",
			debutYear: 2006,
			externalIds: [
				{ service: "imdb", id: "tt0381061" },
				{ service: "themoviedb", id: "36557-casino-royale" },
			],
		},
		{
			trackGuid: "c88551a7-dd77-4692-9039-577ab8608958",
			title: "Cast Away",
			audioUrl: "audio/movies/Cast_Away.2000.mp3",
			audioClips: [
				{
					audioStartInSeconds: 27,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 27,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 27,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Cast_Away.2000.jpg",
			debutYear: 2000,
			externalIds: [
				{ service: "imdb", id: "tt0162222" },
				{ service: "themoviedb", id: "8358-cast-away" },
			],
		},
		{
			trackGuid: "f7d23814-3292-4ac8-a1d8-2e271e3929b1",
			title: "Catch Me If You Can",
			audioUrl: "audio/movies/Catch_Me_If_You_Can.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 9,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 9,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 9,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Catch_Me_If_You_Can.2002.jpg",
			debutYear: 2002,
			externalIds: [
				{ service: "imdb", id: "tt0264464" },
				{ service: "themoviedb", id: "640-catch-me-if-you-can" },
			],
		},
		{
			trackGuid: "0b134a38-fbcc-4ba4-a577-3c83a59cc50d",
			title: "Chariots of Fire",
			audioUrl: "audio/movies/Chariots_of_Fire.1981.mp3",
			audioClips: [
				{
					audioStartInSeconds: 12,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 12,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 12,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Chariots_of_Fire.1981.jpg",
			debutYear: 1981,
			externalIds: [
				{ service: "imdb", id: "tt0082158" },
				{ service: "themoviedb", id: "9443-chariots-of-fire" },
			],
		},
		{
			trackGuid: "37b131b5-4784-4e4e-a890-ad47a06e001d",
			title: "Cinema Paradiso",
			audioUrl: "audio/movies/Cinema_Paradiso.1988.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Cinema_Paradiso.1988.jpg",
			debutYear: 1988,
		},
		{
			trackGuid: "b85fe19e-fcb9-46d9-8576-9e5b6b328e0b",
			title: "Close Encounters of the Third Kind",
			audioUrl:
				"audio/movies/Close_Encounters_of_the_Third_Kind.1977.mp3",
			audioClips: [
				{
					audioStartInSeconds: 35,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 35,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 35,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl:
				"covers/movies/Close_Encounters_of_the_Third_Kind.1977.jpg",
			debutYear: 1977,
			externalIds: [
				{ service: "imdb", id: "tt0075860" },
				{
					service: "themoviedb",
					id: "840-close-encounters-of-the-third-kind",
				},
			],
		},
		{
			trackGuid: "57bbd7c1-15ba-49ae-8d43-e725e2c2dd84",
			title: "Conan the Barbarian",
			audioUrl: "audio/movies/Conan_the_Barbarian.1982.mp3",
			audioClips: [
				{
					audioStartInSeconds: 67,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 67,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 67,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Conan_the_Barbarian.1982.jpg",
			debutYear: 1982,
			externalIds: [
				{ service: "imdb", id: "tt0082198" },
				{ service: "themoviedb", id: "9387-conan-the-barbarian" },
			],
		},
		{
			trackGuid: "b59ec7ec-eba7-44b5-b5be-72fb671856d6",
			title: "Cool Runnings",
			audioUrl: "audio/movies/Cool_Runnings.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 78,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 78,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 78,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Cool_Runnings.1993.jpg",
			debutYear: 1993,
			externalIds: [
				{ service: "imdb", id: "tt0106611" },
				{ service: "themoviedb", id: "864-cool-runnings" },
			],
		},
		{
			trackGuid: "c711ef19-00ed-48b2-9615-453165994361",
			title: "Django Unchained",
			audioUrl: "audio/movies/Django_Unchained.2012.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Django_Unchained.2012.jpg",
			debutYear: 2012,
			externalIds: [
				{ service: "imdb", id: "tt1853728" },
				{ service: "themoviedb", id: "68718-django-unchained" },
			],
		},
		{
			trackGuid: "a5b9880e-c82d-412d-84b3-79f379b840c3",
			title: "Doctor Zhivago",
			audioUrl: "audio/movies/Doctor_Zhivago.1965.mp3",
			audioClips: [
				{
					audioStartInSeconds: 60,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 60,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 60,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Doctor_Zhivago.1965.jpg",
			debutYear: 1965,
		},
		{
			trackGuid: "81de0be3-c884-41f8-a6b4-193e6c263f99",
			title: "Dune",
			audioUrl: "audio/movies/Dune.2021.mp3",
			audioClips: [
				{
					audioStartInSeconds: 78,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 78,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 78,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Dune.2021.jpg",
			debutYear: 2021,
			externalIds: [
				{ service: "imdb", id: "tt1160419" },
				{ service: "themoviedb", id: "438631-dune" },
			],
		},
		{
			trackGuid: "96a42a1e-0516-4e95-9005-7cced72b3406",
			title: "E.T. the Extra-Terrestrial",
			audioUrl: "audio/movies/E_T_the_Extra_Terrestrial.1982.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/E_T_the_Extra_Terrestrial.1982.jpg",
			debutYear: 1982,
			externalIds: [
				{ service: "imdb", id: "tt0083866" },
				{ service: "themoviedb", id: "601-e-t-the-extra-terrestrial" },
			],
		},
		{
			trackGuid: "e8678f1b-0e9a-482c-b54c-4087aad32797",
			title: "Ex Machina",
			audioUrl: "audio/movies/Ex_Machina.2014.mp3",
			audioClips: [
				{
					audioStartInSeconds: 37,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 37,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 37,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Ex_Machina.2014.jpg",
			debutYear: 2014,
			externalIds: [
				{ service: "imdb", id: "tt0470752" },
				{ service: "themoviedb", id: "264660-ex-machina" },
			],
		},
		{
			trackGuid: "7a452bb6-0bab-4b07-8ad2-28dfa2da3c22",
			title: "Flashdance",
			audioUrl: "audio/movies/Flashdance.1983.mp3",
			audioClips: [
				{
					audioStartInSeconds: 46,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 36,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 36,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Flashdance.1983.jpg",
			debutYear: 1983,
			externalIds: [
				{ service: "imdb", id: "tt0085549" },
				{ service: "themoviedb", id: "535-flashdance" },
			],
		},
		{
			trackGuid: "4dd1478a-dc41-46f0-8e8b-9d9c43bb2d85",
			title: "Forrest Gump",
			audioUrl: "audio/movies/Forrest_Gump.1994.mp3",
			audioClips: [
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Forrest_Gump.1994.jpg",
			debutYear: 1994,
			externalIds: [
				{ service: "imdb", id: "tt0109830" },
				{ service: "themoviedb", id: "13-forrest-gump" },
			],
		},
		{
			trackGuid: "41619923-1dff-4741-a5db-59d29f6505b8",
			title: "Ghostbusters",
			audioUrl: "audio/movies/Ghostbusters.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Ghostbusters.1984.jpg",
			debutYear: 1984,
			externalIds: [
				{ service: "imdb", id: "tt0087332" },
				{ service: "themoviedb", id: "620-ghostbusters" },
			],
		},
		{
			trackGuid: "fb0ed718-296f-4e6c-9dd4-cd4a5b72e65c",
			title: "Gladiator",
			audioUrl: "audio/movies/Gladiator.2000.mp3",
			audioClips: [
				{
					audioStartInSeconds: 49,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 49,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 49,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Gladiator.2000.jpg",
			debutYear: 2000,
			externalIds: [
				{ service: "imdb", id: "tt0172495" },
				{ service: "themoviedb", id: "98-gladiator" },
			],
		},
		{
			trackGuid: "62eed258-744c-4c8a-a36e-5a4dd7e22af4",
			title: "Grindhouse",
			audioUrl: "audio/movies/Grindhouse.2007.mp3",
			audioClips: [
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Grindhouse.2007.jpg",
			debutYear: 2007,
		},
		{
			trackGuid: "a69df130-9c3a-40bf-b0f3-86234c832e92",
			title: "Hamlet 2",
			audioUrl: "audio/movies/Hamlet_2.2008.mp3",
			audioClips: [
				{
					audioStartInSeconds: 45,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 45,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 45,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Hamlet_2.2008.jpg",
			debutYear: 2008,
			externalIds: [
				{ service: "imdb", id: "tt1104733" },
				{ service: "themoviedb", id: "12621-hamlet-2" },
			],
		},
		{
			trackGuid: "384b5d1b-6e21-440a-a74a-25917c8d9828",
			title: "Harry Potter and the Philosopher's Stone",
			audioUrl:
				"audio/movies/Harry_Potter_and_the_Philosopher's_Stone.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl:
				"covers/movies/Harry_Potter_and_the_Philosopher's_Stone.2001.jpg",
			debutYear: 2001,
			externalIds: [
				{ service: "imdb", id: "tt0241527" },
				{
					service: "themoviedb",
					id: "671-harry-potter-and-the-philosopher-s-stone",
				},
			],
		},
		{
			trackGuid: "8a0785b9-4e8c-4211-b098-e07fb7e2e3bd",
			title: "Home Alone",
			audioUrl: "audio/movies/Home_Alone.1990.mp3",
			audioClips: [
				{
					audioStartInSeconds: 233,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 233,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 233,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Home_Alone.1990.jpg",
			debutYear: 1990,
			externalIds: [
				{ service: "imdb", id: "tt0099785" },
				{ service: "themoviedb", id: "771-home-alone" },
			],
		},
		{
			trackGuid: "f7ee8677-7a02-4dd6-af30-7e24ba609416",
			title: "Idiocracy",
			audioUrl: "audio/movies/Idiocracy.2006.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Idiocracy.2006.jpg",
			debutYear: 2006,
			externalIds: [
				{ service: "imdb", id: "tt0387808" },
				{ service: "themoviedb", id: "7512-idiocracy" },
			],
		},
		{
			trackGuid: "40eee425-1a1b-451a-85cb-a176eccf4921",
			title: "Inception",
			audioUrl: "audio/movies/Inception.2010.mp3",
			audioClips: [
				{
					audioStartInSeconds: 61,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 61,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 61,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Inception.2010.jpg",
			debutYear: 2010,
			externalIds: [
				{ service: "imdb", id: "tt1375666" },
				{ service: "themoviedb", id: "27205-inception" },
			],
		},
		{
			trackGuid: "c627050a-6f83-4d37-b8e2-6e8ef025f65c",
			title: "Indiana Jones and the Raiders of the Lost Ark",
			audioUrl:
				"audio/movies/Indiana_Jones_and_the_Raiders_of_the_Lost_Ark.1981.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl:
				"covers/movies/Indiana_Jones_and_the_Raiders_of_the_Lost_Ark.1981.jpg",
			debutYear: 1981,
			externalIds: [
				{ service: "imdb", id: "tt0082971" },
				{ service: "themoviedb", id: "85-raiders-of-the-lost-ark" },
			],
		},
		{
			trackGuid: "d825768a-7e5d-4936-9615-ea48705522b2",
			title: "Inglorious Basterds",
			audioUrl: "audio/movies/Inglorious_Basterds.2009.mp3",
			audioClips: [
				{
					audioStartInSeconds: 21,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 21,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 21,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Inglorious_Basterds.2009.jpg",
			debutYear: 2009,
			externalIds: [
				{ service: "imdb", id: "tt0361748" },
				{ service: "themoviedb", id: "16869-inglourious-basterds" },
			],
		},
		{
			trackGuid: "db051678-9f09-4e22-9066-13e13ddf7786",
			title: "Interstellar",
			audioUrl: "audio/movies/Interstellar.2014.mp3",
			audioClips: [
				{
					audioStartInSeconds: 155,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 155,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 155,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Interstellar.2014.jpg",
			debutYear: 2014,
			externalIds: [
				{ service: "imdb", id: "tt0816692" },
				{ service: "themoviedb", id: "157336-interstellar" },
			],
		},
		{
			trackGuid: "86cda438-29ef-4bc3-bd38-82042cc5ce34",
			title: "Iron Sky",
			audioUrl: "audio/movies/Iron_Sky.2012.mp3",
			audioClips: [
				{
					audioStartInSeconds: 39,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 39,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 39,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Iron_Sky.2012.jpg",
			debutYear: 2012,
			externalIds: [
				{ service: "imdb", id: "tt1034314" },
				{ service: "themoviedb", id: "10679-iron-sky" },
			],
		},
		{
			trackGuid: "a5ede9e5-6161-4ebc-b891-cd2cbe506d4c",
			title: "Jackass: The Movie",
			audioUrl: "audio/movies/Jackass_The_Movie.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Jackass_The_Movie.2002.jpg",
			debutYear: 2002,
			externalIds: [
				{ service: "imdb", id: "tt0322802" },
				{ service: "themoviedb", id: "9012-jackass-the-movie" },
			],
		},
		{
			trackGuid: "15bc18d1-bb63-4b01-b659-740101d56429",
			title: "Jaws",
			audioUrl: "audio/movies/Jaws.1975.mp3",
			audioClips: [
				{
					audioStartInSeconds: 30,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 19,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 19,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Jaws.1975.jpg",
			debutYear: 1975,
			externalIds: [
				{ service: "imdb", id: "tt0073195" },
				{ service: "themoviedb", id: "578-jaws" },
			],
		},
		{
			trackGuid: "aeec4b06-f08f-47ff-b82e-a0a21521958a",
			title: "Jurassic Park",
			audioUrl: "audio/movies/Jurassic_Park.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 270,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 270,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 270,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Jurassic_Park.1993.jpg",
			debutYear: 1993,
			externalIds: [
				{ service: "imdb", id: "tt0107290" },
				{ service: "themoviedb", id: "329-jurassic-park" },
			],
		},
		{
			trackGuid: "f92640b4-6ee7-4572-8b58-0f17209cc9ae",
			title: "Kill Bill: Vol. 1",
			audioUrl: "audio/movies/Kill_Bill_Vol_1.2003.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Kill_Bill_Vol_1.2003.jpg",
			debutYear: 2003,
			externalIds: [
				{ service: "imdb", id: "tt0266697" },
				{ service: "themoviedb", id: "24-kill-bill-vol-1" },
			],
		},
		{
			trackGuid: "bacb415d-1271-4a4c-9ae1-1d0096afdd05",
			title: "Kung Fu Panda",
			audioUrl: "audio/movies/Kung_Fu_Panda.2008.mp3",
			audioClips: [
				{
					audioStartInSeconds: 55,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 55,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 55,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 3,
			coverUrl: "covers/movies/Kung_Fu_Panda.2008.jpg",
			debutYear: 2008,
		},
		{
			trackGuid: "1b3ca879-faae-4bca-9e39-88bc26995b01",
			title: "Life of Brian",
			audioUrl: "audio/movies/Life_of_Brian.1979.mp3",
			audioClips: [
				{
					audioStartInSeconds: 24,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 24,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 24,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Life_of_Brian.1979.jpg",
			debutYear: 1979,
			externalIds: [
				{ service: "imdb", id: "tt0079470" },
				{ service: "themoviedb", id: "583-life-of-brian" },
			],
		},
		{
			trackGuid: "a3822537-64f9-41fa-b1bf-305293122aa7",
			title: "Men in Black",
			audioUrl: "audio/movies/Men_in_Black.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 51,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 51,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 51,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Men_in_Black.1997.jpg",
			debutYear: 1997,
			externalIds: [
				{ service: "imdb", id: "tt0119654" },
				{ service: "themoviedb", id: "607-men-in-black" },
			],
		},
		{
			trackGuid: "c5c70f1c-160c-46cf-b7fa-c2cb7f22856d",
			title: "Mission: Impossible",
			audioUrl: "audio/movies/Mission_Impossible.1996.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Mission_Impossible.1996.jpg",
			debutYear: 1996,
			externalIds: [
				{ service: "imdb", id: "tt0117060" },
				{ service: "themoviedb", id: "954-mission-impossible" },
			],
		},
		{
			trackGuid: "72fd6c97-1682-4458-b31c-aba011ec56da",
			title: "Monsters, Inc.",
			audioUrl: "audio/movies/Monsters_Inc.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 3,
			coverUrl: "covers/movies/Monsters_Inc.2001.jpg",
			debutYear: 2001,
			externalIds: [
				{ service: "imdb", id: "tt0198781" },
				{ service: "themoviedb", id: "585-monsters-inc" },
			],
		},
		{
			trackGuid: "786b7c6d-ebbc-4fbf-89e4-288910a3c41f",
			title: "Monty Python and the Holy Grail",
			audioUrl: "audio/movies/Monty_Python_and_the_Holy_Grail.1975.mp3",
			audioClips: [
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Monty_Python_and_the_Holy_Grail.1975.jpg",
			debutYear: 1975,
			externalIds: [
				{ service: "imdb", id: "tt0071853" },
				{
					service: "themoviedb",
					id: "762-monty-python-and-the-holy-grail",
				},
			],
		},
		{
			trackGuid: "51c853af-e40b-4e2a-86b4-ebf19bcfee03",
			title: "My Big Fat Greek Wedding",
			audioUrl: "audio/movies/My_Big_Fat_Greek_Wedding.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 21,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 21,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 21,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/My_Big_Fat_Greek_Wedding.2002.jpg",
			debutYear: 2002,
			externalIds: [
				{ service: "imdb", id: "tt0259446" },
				{ service: "themoviedb", id: "8346-my-big-fat-greek-wedding" },
			],
		},
		{
			trackGuid: "72c45f2a-13f8-4f78-ab83-640a367a328f",
			title: "Once Upon a Time... in Hollywood",
			audioUrl: "audio/movies/Once_Upon_a_Time_in_Hollywood.2019.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Once_Upon_a_Time_in_Hollywood.2019.jpg",
			debutYear: 2019,
			externalIds: [
				{ service: "imdb", id: "tt7131622" },
				{
					service: "themoviedb",
					id: "466272-once-upon-a-time-in-hollywood",
				},
			],
		},
		{
			trackGuid: "992c0116-7a3d-481e-ac30-9abb5bd5bae5",
			title: "Pacific Rim",
			audioUrl: "audio/movies/Pacific_Rim.2013.mp3",
			audioClips: [
				{
					audioStartInSeconds: 40,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 40,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 40,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Pacific_Rim.2013.jpg",
			debutYear: 2013,
			externalIds: [
				{ service: "imdb", id: "tt1663662" },
				{ service: "themoviedb", id: "68726-pacific-rim" },
			],
		},
		{
			trackGuid: "a0d61e99-ee87-4981-8ee3-df37be2ac556",
			title: "Pee-wee's Big Adventure",
			audioUrl: "audio/movies/Pee_wee's_Big_Adventure.1985.mp3",
			audioClips: [
				{
					audioStartInSeconds: 10,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Pee_wee's_Big_Adventure.1985.jpg",
			debutYear: 1985,
			externalIds: [
				{ service: "imdb", id: "tt0089791" },
				{ service: "themoviedb", id: "5683-pee-wee-s-big-adventure" },
			],
		},
		{
			trackGuid: "34aa0fcf-d2be-49cb-b6ef-8d6172cfc8f7",
			title: "Pirates of the Caribbean: The Curse of the Black Pearl",
			audioUrl:
				"audio/movies/Pirates_of_the_Caribbean_The_Curse_of_the_Black_Pearl.2003.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl:
				"covers/movies/Pirates_of_the_Caribbean_The_Curse_of_the_Black_Pearl.2003.jpg",
			debutYear: 2003,
			externalIds: [
				{ service: "imdb", id: "tt0325980" },
				{
					service: "themoviedb",
					id: "22-pirates-of-the-caribbean-the-curse-of-the-black-pearl",
				},
			],
		},
		{
			trackGuid: "33c6c2b7-4301-493b-b727-f805db5cc4ad",
			title: "Police Academy",
			audioUrl: "audio/movies/Police_Academy.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 20,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 20,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 20,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Police_Academy.1984.jpg",
			debutYear: 1984,
			externalIds: [
				{ service: "imdb", id: "tt0087928" },
				{ service: "themoviedb", id: "9336-police-academy" },
			],
		},
		{
			trackGuid: "9c6fdfef-f310-44c4-b4ef-a8e2fedbbddf",
			title: "Princess Mononoke",
			audioUrl: "audio/movies/Princess_Mononoke.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 101,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 101,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 101,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 5,
			coverUrl: "covers/movies/Princess_Mononoke.1997.jpg",
			debutYear: 1997,
		},
		{
			trackGuid: "6bc3f493-afeb-4076-a0d3-cf19226b2f5b",
			title: "Psycho",
			audioUrl: "audio/movies/Psycho.1960.mp3",
			audioClips: [
				{
					audioStartInSeconds: 272,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 272,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 272,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Psycho.1960.jpg",
			debutYear: 1960,
			externalIds: [
				{ service: "imdb", id: "tt0054215" },
				{ service: "themoviedb", id: "539-psycho" },
			],
		},
		{
			trackGuid: "d2fce861-3abd-40e5-92b8-26da5eb506ff",
			title: "Pulp Fiction",
			audioUrl: "audio/movies/Pulp_Fiction.1994.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Pulp_Fiction.1994.jpg",
			debutYear: 1994,
			externalIds: [
				{ service: "imdb", id: "tt0110912" },
				{ service: "themoviedb", id: "680-pulp-fiction" },
			],
		},
		{
			trackGuid: "8a69a712-74ec-4382-a725-e5939e847513",
			title: "Rocky",
			audioUrl: "audio/movies/Rocky.1976.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Rocky.1976.jpg",
			debutYear: 1976,
			externalIds: [
				{ service: "imdb", id: "tt0075148" },
				{ service: "themoviedb", id: "1366-rocky" },
			],
		},
		{
			trackGuid: "f59d9e4e-6427-400e-92b1-96669a61021d",
			title: "Saturday Night Fever",
			audioUrl: "audio/movies/Saturday_Night_Fever.1977.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Saturday_Night_Fever.1977.jpg",
			debutYear: 1977,
			externalIds: [
				{ service: "imdb", id: "tt0076666" },
				{ service: "themoviedb", id: "11009-saturday-night-fever" },
			],
		},
		{
			trackGuid: "34b7b617-8d9d-4495-9b17-e314cbca6fb7",
			title: "Saving Private Ryan",
			audioUrl: "audio/movies/Saving_Private_Ryan.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 70,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 70,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 70,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/Saving_Private_Ryan.1998.jpg",
			debutYear: 1998,
			externalIds: [
				{ service: "imdb", id: "tt0120815" },
				{ service: "themoviedb", id: "857-saving-private-ryan" },
			],
		},
		{
			trackGuid: "cf67c0cc-7cdf-4a69-b8fc-962e5434b50a",
			title: "Schindler's List",
			audioUrl: "audio/movies/Schindler's_List.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 12,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 12,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 12,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Schindler's_List.1993.jpg",
			debutYear: 1993,
			externalIds: [
				{ service: "imdb", id: "tt0108052" },
				{ service: "themoviedb", id: "424-schindler-s-list" },
			],
		},
		{
			trackGuid: "8740ae7c-0ef0-469e-9317-8d1d30c67ddb",
			title: "Shrek",
			audioUrl: "audio/movies/Shrek.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 3,
			coverUrl: "covers/movies/Shrek.2001.jpg",
			debutYear: 2001,
			externalIds: [
				{ service: "imdb", id: "tt0126029" },
				{ service: "themoviedb", id: "808-shrek" },
			],
		},
		{
			trackGuid: "491f6a3b-f722-4e48-a431-927370803cf8",
			title: "Sin City",
			audioUrl: "audio/movies/Sin_City.2005.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action", "comics"],
			difficulty: 8,
			coverUrl: "covers/movies/Sin_City.2005.jpg",
			debutYear: 2005,
			externalIds: [
				{ service: "imdb", id: "tt0401792" },
				{ service: "themoviedb", id: "187-sin-city" },
			],
		},
		{
			trackGuid: "4346473c-c2d6-4c2c-94e5-c3b4e5d0df0d",
			title: "Spider-Man",
			audioUrl: "audio/movies/Spider_Man.2002.mp3",
			audioClips: [
				{
					audioStartInSeconds: 102,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 102,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 102,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action", "comics"],
			difficulty: 2,
			coverUrl: "covers/movies/Spider_Man.2002.jpg",
			debutYear: 2002,
			externalIds: [
				{ service: "imdb", id: "tt0145487" },
				{ service: "themoviedb", id: "557-spider-man" },
			],
		},
		{
			trackGuid: "141788d7-7c66-4fff-860c-6be99378af77",
			title: "Spirited Away",
			audioUrl: "audio/movies/Spirited_Away.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 2,
			coverUrl: "covers/movies/Spirited_Away.2001.jpg",
			debutYear: 2001,
		},
		{
			trackGuid: "e20835d4-d3e0-4e53-b4c1-f583095f80e6",
			title: "Star Wars: Episode IV - A New Hope",
			audioUrl: "audio/movies/Star_Wars_Episode_IV_A_New_Hope.1977.mp3",
			audioClips: [
				{
					audioStartInSeconds: 50,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 45,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 45,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Star_Wars_Episode_IV_A_New_Hope.1977.jpg",
			debutYear: 1977,
			externalIds: [
				{ service: "imdb", id: "tt0076759" },
				{ service: "themoviedb", id: "11-star-wars" },
			],
		},
		{
			trackGuid: "ac11c75a-8570-4683-aef0-df19d86487a0",
			title: "Stargate",
			audioUrl: "audio/movies/Stargate.1994.mp3",
			audioClips: [
				{
					audioStartInSeconds: 37,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 32,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 32,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/Stargate.1994.jpg",
			debutYear: 1994,
			externalIds: [
				{ service: "imdb", id: "tt0111282" },
				{ service: "themoviedb", id: "2164-stargate" },
			],
		},
		{
			trackGuid: "33c3263c-1e83-4fdf-8f4f-68129a00e143",
			title: "Superman",
			audioUrl: "audio/movies/Superman.1978.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action", "comics"],
			difficulty: 5,
			coverUrl: "covers/movies/Superman.1978.jpg",
			debutYear: 1978,
			externalIds: [
				{ service: "imdb", id: "tt0078346" },
				{ service: "themoviedb", id: "1924-superman" },
			],
		},
		{
			trackGuid: "57920492-1247-40a6-ae35-b732544bb9fb",
			title: "Taxi Driver",
			audioUrl: "audio/movies/Taxi_Driver.1976.mp3",
			audioClips: [
				{
					audioStartInSeconds: 53,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 53,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 53,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Taxi_Driver.1976.jpg",
			debutYear: 1976,
			externalIds: [
				{ service: "imdb", id: "tt0075314" },
				{ service: "themoviedb", id: "103-taxi-driver" },
			],
		},
		{
			trackGuid: "9ea85046-d32a-4a59-acd5-89bc719883be",
			title: "The Avengers",
			audioUrl: "audio/movies/The_Avengers.2012.mp3",
			audioClips: [
				{
					audioStartInSeconds: 65,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 65,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 65,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action", "comics"],
			difficulty: 3,
			coverUrl: "covers/movies/The_Avengers.2012.jpg",
			debutYear: 2012,
			externalIds: [
				{ service: "imdb", id: "tt0848228" },
				{ service: "themoviedb", id: "24428-the-avengers" },
			],
		},
		{
			trackGuid: "d54f31f7-35e3-4e09-871d-fa735a067649",
			title: "The Big Lebowski",
			audioUrl: "audio/movies/The_Big_Lebowski.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/The_Big_Lebowski.1998.jpg",
			debutYear: 1998,
			externalIds: [
				{ service: "imdb", id: "tt0118715" },
				{ service: "themoviedb", id: "115-the-big-lebowski" },
			],
		},
		{
			trackGuid: "ecba5443-732a-41eb-9ca0-4f9d222a7acb",
			title: "The Bridge on the River Kwai",
			audioUrl: "audio/movies/The_Bridge_On_the_River_Kwai.1957.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl: "covers/movies/The_Bridge_On_the_River_Kwai.1957.jpg",
			debutYear: 1957,
		},
		{
			trackGuid: "248623e6-f6b0-49de-8f6f-676bf34c6ea2",
			title: "The Dark Knight",
			audioUrl: "audio/movies/The_Dark_Knight.2008.mp3",
			audioClips: [
				{
					audioStartInSeconds: 32,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 32,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 32,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action", "comics"],
			difficulty: 2,
			coverUrl: "covers/movies/The_Dark_Knight.2008.jpg",
			debutYear: 2008,
			externalIds: [
				{ service: "imdb", id: "tt0468569" },
				{ service: "themoviedb", id: "155-the-dark-knight" },
			],
		},
		{
			trackGuid: "b568b634-5f07-4425-9899-5ac5fa4c45e8",
			title: "The Fifth Element",
			audioUrl: "audio/movies/The_Fifth_Element.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 215,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 215,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 215,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/The_Fifth_Element.1997.jpg",
			debutYear: 1997,
			externalIds: [
				{ service: "imdb", id: "tt0119116" },
				{ service: "themoviedb", id: "18-the-fifth-element" },
			],
		},
		{
			trackGuid: "d5ac0aac-50c4-48bf-8568-8575df8ca2c0",
			title: "The Godfather",
			audioUrl: "audio/movies/The_Godfather.1972.mp3",
			audioClips: [
				{
					audioStartInSeconds: 13,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 13,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 13,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/The_Godfather.1972.jpg",
			debutYear: 1972,
			externalIds: [
				{ service: "imdb", id: "tt0068646" },
				{ service: "themoviedb", id: "238-the-godfather" },
			],
		},
		{
			trackGuid: "6e58ad29-365d-4ce9-bb6e-488fb5b7535b",
			title: "The Good, the Bad and the Ugly",
			audioUrl: "audio/movies/The_Good_the_Bad_and_the_Ugly.1966.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/The_Good_the_Bad_and_the_Ugly.1966.jpg",
			debutYear: 1966,
			externalIds: [
				{ service: "imdb", id: "tt0060196" },
				{
					service: "themoviedb",
					id: "429-il-buono-il-brutto-il-cattivo",
				},
			],
		},
		{
			trackGuid: "033110fd-7174-4c8b-97dd-6e5ec55603ef",
			title: "The Goonies",
			audioUrl: "audio/movies/The_Goonies.1985.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/The_Goonies.1985.jpg",
			debutYear: 1985,
		},
		{
			trackGuid: "2950e90d-7773-4c74-885b-cf647119a6aa",
			title: "The Hunger Games",
			audioUrl: "audio/movies/The_Hunger_Games.2012.mp3",
			audioClips: [
				{
					audioStartInSeconds: 208,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 208,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 208,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/The_Hunger_Games.2012.jpg",
			debutYear: 2012,
		},
		{
			trackGuid: "a19cc624-8c1e-4976-b71d-793acae68271",
			title: "The Hunt for Red October",
			audioUrl: "audio/movies/The_Hunt_for_Red_October.1990.mp3",
			audioClips: [
				{
					audioStartInSeconds: 84,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 84,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 84,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/The_Hunt_for_Red_October.1990.jpg",
			debutYear: 1990,
			externalIds: [
				{ service: "imdb", id: "tt0099810" },
				{ service: "themoviedb", id: "1669-the-hunt-for-red-october" },
			],
		},
		{
			trackGuid: "2a81957e-89aa-46c9-833f-36fe27dbe59e",
			title: "The Lego Movie",
			audioUrl: "audio/movies/The_Lego_Movie.2014.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 5,
			coverUrl: "covers/movies/The_Lego_Movie.2014.jpg",
			debutYear: 2014,
			externalIds: [
				{ service: "imdb", id: "tt1490017" },
				{ service: "themoviedb", id: "137106-the-lego-movie" },
			],
		},
		{
			trackGuid: "320e982a-8785-4561-912e-7eb9da8c5d92",
			title: "The Lion King",
			audioUrl: "audio/movies/The_Lion_King.1994.mp3",
			audioClips: [
				{
					audioStartInSeconds: 81,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 81,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 81,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 3,
			coverUrl: "covers/movies/The_Lion_King.1994.jpg",
			debutYear: 1994,
			externalIds: [
				{ service: "imdb", id: "tt0110357" },
				{ service: "themoviedb", id: "8587-the-lion-king" },
			],
		},
		{
			trackGuid: "23853cef-ed91-4dba-bc36-7c215322da98",
			title: "The Lord of the Rings: The Fellowship of the Ring",
			audioUrl:
				"audio/movies/The_Lord_of_the_Rings_The_Fellowship_of_the_Ring.2001.mp3",
			audioClips: [
				{
					audioStartInSeconds: 75,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 72,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 72,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl:
				"covers/movies/The_Lord_of_the_Rings_The_Fellowship_of_the_Ring.2001.jpg",
			debutYear: 2001,
			externalIds: [
				{ service: "imdb", id: "tt0120737" },
				{
					service: "themoviedb",
					id: "120-the-lord-of-the-rings-the-fellowship-of-the-ring",
				},
			],
		},
		{
			trackGuid: "4a01d1ff-8885-4b72-a3a9-651545ed3d4c",
			title: "The Matrix",
			audioUrl: "audio/movies/The_Matrix.1999.mp3",
			audioClips: [
				{
					audioStartInSeconds: 14,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 7,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 3,
			coverUrl: "covers/movies/The_Matrix.1999.jpg",
			debutYear: 1999,
			externalIds: [
				{ service: "imdb", id: "tt0133093" },
				{ service: "themoviedb", id: "603-the-matrix" },
			],
		},
		{
			trackGuid: "ed7cff60-65e3-42fd-a58c-20cf16956673",
			title: "The Naked Gun: From the Files of Police Squad!",
			audioUrl:
				"audio/movies/The_Naked_Gun_From_the_Files_of_Police_Squad.1988.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 8,
			coverUrl:
				"covers/movies/The_Naked_Gun_From_the_Files_of_Police_Squad.1988.jpg",
			debutYear: 1988,
			externalIds: [
				{ service: "imdb", id: "tt0095705" },
				{
					service: "themoviedb",
					id: "37136-the-naked-gun-from-the-files-of-police-squad",
				},
			],
		},
		{
			trackGuid: "218c7d1b-3118-496f-b071-6496d24253c1",
			title: "The Neverending Story",
			audioUrl: "audio/movies/The_NeverEnding_Story.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/The_NeverEnding_Story.1984.jpg",
			debutYear: 1984,
		},
		{
			trackGuid: "1fcf04c1-ad18-41ca-b1d5-1accb0f6f5d0",
			title: "The Nightmare Before Christmas",
			audioUrl: "audio/movies/The_Nightmare_Before_Christmas.1993.mp3",
			audioClips: [
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 15,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 1,
			coverUrl: "covers/movies/The_Nightmare_Before_Christmas.1993.jpg",
			debutYear: 1993,
			externalIds: [
				{ service: "imdb", id: "tt0107688" },
				{
					service: "themoviedb",
					id: "9479-the-nightmare-before-christmas",
				},
			],
		},
		{
			trackGuid: "2e286408-c7ff-4625-8ffe-5dea42ac83bf",
			title: "The Rock",
			audioUrl: "audio/movies/The_Rock.1996.mp3",
			audioClips: [
				{
					audioStartInSeconds: 256,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 256,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 256,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/The_Rock.1996.jpg",
			debutYear: 1996,
			externalIds: [
				{ service: "imdb", id: "tt0117500" },
				{ service: "themoviedb", id: "9802-the-rock" },
			],
		},
		{
			trackGuid: "3ff98132-de27-48c0-9a80-70ea121b1572",
			title: "The Terminator",
			audioUrl: "audio/movies/The_Terminator.1984.mp3",
			audioClips: [
				{
					audioStartInSeconds: 64,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 58,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 58,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/The_Terminator.1984.jpg",
			debutYear: 1984,
			externalIds: [
				{ service: "imdb", id: "tt0088247" },
				{ service: "themoviedb", id: "218-the-terminator" },
			],
		},
		{
			trackGuid: "bbfe3154-d626-434c-8fcb-977e3cfa2567",
			title: "The Truman Show",
			audioUrl: "audio/movies/The_Truman_Show.1998.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/The_Truman_Show.1998.jpg",
			debutYear: 1998,
		},
		{
			trackGuid: "9b4ceafb-f9cd-4e0d-a9b4-8fe0cbdfeec8",
			title: "Titanic",
			audioUrl: "audio/movies/Titanic.1997.mp3",
			audioClips: [
				{
					audioStartInSeconds: 54,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 54,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 54,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Titanic.1997.jpg",
			debutYear: 1997,
			externalIds: [
				{ service: "imdb", id: "tt0120338" },
				{ service: "themoviedb", id: "597-titanic" },
			],
		},
		{
			trackGuid: "2ca50d2f-1599-40e4-b181-b4ad960ef356",
			title: "Top Gun",
			audioUrl: "audio/movies/Top_Gun.1986.mp3",
			audioClips: [
				{
					audioStartInSeconds: 9,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 1,
			coverUrl: "covers/movies/Top_Gun.1986.jpg",
			debutYear: 1986,
			externalIds: [
				{ service: "imdb", id: "tt0092099" },
				{ service: "themoviedb", id: "744-top-gun" },
			],
		},
		{
			trackGuid: "ff538908-a2d2-424f-a82f-72261ee33b24",
			title: "Toy Story",
			audioUrl: "audio/movies/Toy_Story.1995.mp3",
			audioClips: [
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 1,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 2,
			coverUrl: "covers/movies/Toy_Story.1995.jpg",
			debutYear: 1995,
			externalIds: [
				{ service: "imdb", id: "tt0114709" },
				{ service: "themoviedb", id: "862-toy-story" },
			],
		},
		{
			trackGuid: "20360672-404a-4f95-bdc1-4a9384441dc5",
			title: "Trainspotting",
			audioUrl: "audio/movies/Trainspotting.1996.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 2,
			coverUrl: "covers/movies/Trainspotting.1996.jpg",
			debutYear: 1996,
		},
		{
			trackGuid: "71e0be3c-97ba-40d7-95e1-2cdd0f459a3f",
			title: "TRON: Legacy",
			audioUrl: "audio/movies/Tron_Legacy.2010.mp3",
			audioClips: [
				{
					audioStartInSeconds: 28,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 28,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 28,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl: "covers/movies/Tron_Legacy.2010.jpg",
			debutYear: 2010,
			externalIds: [
				{ service: "imdb", id: "tt1104001" },
				{ service: "themoviedb", id: "20526-tron-legacy" },
			],
		},
		{
			trackGuid: "544ab29a-7094-4fb6-b659-6f6a9b4f382b",
			title: "Up",
			audioUrl: "audio/movies/Up.2009.mp3",
			audioClips: [
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 0,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "animated"],
			difficulty: 3,
			coverUrl: "covers/movies/Up.2009.jpg",
			debutYear: 2009,
			externalIds: [
				{ service: "imdb", id: "tt1049413" },
				{ service: "themoviedb", id: "14160-up" },
			],
		},
		{
			trackGuid: "18b16bc6-d71f-44a7-9380-5c52ed63b061",
			title: "Willy Wonka & the Chocolate Factory",
			audioUrl:
				"audio/movies/Willy_Wonka_and_the_Chocolate_Factory.1971.mp3",
			audioClips: [
				{
					audioStartInSeconds: 6,
					audioLengthInSeconds: 30,
				},
				{
					audioStartInSeconds: 6,
					audioLengthInSeconds: 45,
				},
				{
					audioStartInSeconds: 6,
					audioLengthInSeconds: 60,
				},
			],
			tags: ["movie", "live_action"],
			difficulty: 5,
			coverUrl:
				"covers/movies/Willy_Wonka_and_the_Chocolate_Factory.1971.jpg",
			debutYear: 1971,
			externalIds: [
				{ service: "imdb", id: "tt0067992" },
				{
					service: "themoviedb",
					id: "252-willy-wonka-the-chocolate-factory",
				},
			],
		},
	],
};
