import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { IGameState } from "../models/game-state";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import GameStateManager from "../utils/game-state-manager";

interface LoopPlayReadyProps {
	gameState: IGameState;
}

const LoopPlayReady = (props: LoopPlayReadyProps) => {
	return (
		<Dialog open>
			<DialogTitle
				sx={{
					backgroundColor:
						GameStateManager.instance.currentPlayer?.colour,
				}}
			>
				{GameStateManager.instance.currentPlayer?.name}, get ready for
				countdown!
			</DialogTitle>
			<DialogContent sx={{ mt: 4 }}>
				<Button
					size="large"
					variant="contained"
					color="success"
					onClick={GameStateManager.instance.startLoopCountdown}
				>
					Play audio!
					<PlayCircleIcon sx={{ marginLeft: 2 }} />
				</Button>
			</DialogContent>
		</Dialog>
	);
};
export default LoopPlayReady;
