import {
	AppBar,
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	Toolbar,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gameModesRoundsMultipliers, IGameState } from "../models/game-state";
import GameStateManager from "../utils/game-state-manager";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

interface GameScoreboardProps {
	gameState: IGameState;
}

const GameScoreboard = (props: GameScoreboardProps) => {
	const { gameState } = props;

	const currentRound = GameStateManager.instance.currentRoundIdx + 1;
	const totalRounds = GameStateManager.instance.totalRounds;
	const currentMultiplier = GameStateManager.instance.currentMultiplier;
	const playerCount = gameState.gameParameters.players.length;
	const gameEnded = gameState.loopHistory.length >= totalRounds * playerCount;

	const [scoreboard, setScoreboard] = useState<(number | null)[][]>([]);

	useEffect(() => {
		const newScoreboard: (number | null)[][] = [];
		for (let i = 0; i < totalRounds; i++) {
			newScoreboard.push(
				gameState.gameParameters.players.map((p) => null)
			);
		}
		gameState.loopHistory.forEach((loop, idx) => {
			newScoreboard[Math.floor(idx / playerCount)][idx % playerCount] =
				loop.pointsAchieved;
		});

		setScoreboard(newScoreboard);
	}, [gameState.loopHistory.length]);

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar position="static">
					<Toolbar>
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1 }}
						>
							Round {currentRound} of {totalRounds} | Multiplier{" "}
							{currentMultiplier}x
						</Typography>
						<Button
							color="success"
							variant="contained"
							onClick={GameStateManager.instance.initLoop}
							disabled={gameEnded}
						>
							Next Player
							<PlayArrowIcon />
						</Button>
					</Toolbar>
				</AppBar>
			</Box>
			<Container>
				<Paper sx={{ mt: 2 }} elevation={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="center" width={100}>
									Multi
								</TableCell>
								{gameState.gameParameters.players.map((p) => (
									<TableCell
										align="center"
										key={p.playerGuid}
										sx={{
											bgcolor: p.colour,
										}}
									>
										{p.name}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{scoreboard.map((row, rIdx) => (
								<TableRow key={rIdx}>
									<TableCell align="center">
										x
										{
											gameModesRoundsMultipliers[
												gameState.gameParameters
													.gameMode
											][rIdx]
										}
									</TableCell>
									{row.map((cell, cIdx) => (
										<TableCell
											align="center"
											key={`${rIdx}${cIdx}`}
										>
											{cell !== null ? cell : " "}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell align="center" sx={{ fontSize: 24 }}>
									Total
								</TableCell>
								{scoreboard
									.reduce((previous, current) => {
										return previous.map(
											(s, idx) => s + current[idx]
										);
									}, new Array(gameState.gameParameters.players.length).fill(0))
									.map((cell, idx) => (
										<TableCell
											key={idx}
											align="center"
											sx={{ fontSize: 24 }}
										>
											<b>{cell}</b>
										</TableCell>
									))}
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
			</Container>
		</>
	);
};
export default GameScoreboard;
