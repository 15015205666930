import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Typography,
} from "@mui/material";
import React from "react";
import { IGameState } from "../models/game-state";
import GameStateManager from "../utils/game-state-manager";

interface LoopCountdownProps {
	gameState: IGameState;
}

const LoopCountdown = (props: LoopCountdownProps) => {
	const { gameState } = props;

	if (
		gameState.currentLoop.audioPlaybackRemaining === undefined ||
		gameState.currentLoop.countdownRemaining === undefined
	) {
		return <></>;
	}

	const progress =
		100 -
		(gameState.currentLoop.countdownRemaining /
			(gameState.gameParameters.additionalCountdown +
				gameState.gameParameters.audioClipLength)) *
			100;

	return (
		<Dialog open fullWidth maxWidth="lg">
			<DialogTitle
				sx={{
					backgroundColor:
						GameStateManager.instance.currentPlayer?.colour,
				}}
			>
				{GameStateManager.instance.currentPlayer?.name}, guess the
				title!!!
			</DialogTitle>
			<DialogContent sx={{ mt: 4 }}>
				<LinearProgress
					variant="determinate"
					value={progress}
					color={
						gameState.currentLoop.audioPlaybackRemaining > 0
							? "success"
							: progress > 95
							? "error"
							: "warning"
					}
					style={{ height: "15px" }}
				/>
				<Typography variant="h1" align="center">
					{Math.ceil(gameState.currentLoop.countdownRemaining)}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={GameStateManager.instance.showLoopAnswer}>
					Skip To Answer
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default LoopCountdown;
