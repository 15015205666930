export interface IUnrevealedTrack {
	trackGuid: string;
	difficulty?: TrackDifficulty;
}

export interface ITrack extends IUnrevealedTrack {
	title: string;
	audioUrl: string;
	audioClips: ITrackAudioClip[];
	tags?: TrackTag[];
	coverUrl?: string;
	debutYear?: number;
	externalIds?: {
		service: TrackExternalService;
		id: string;
	}[];
}

export interface ITrackAudioClip {
	audioStartInSeconds: number;
	audioLengthInSeconds: TrackAudioLengthInSeconds;
}
export interface IExternalId {
	service: TrackExternalService;
	id: string;
}

export type TrackExternalService = "imdb" | "thetvdb" | "themoviedb";
export type TrackTag =
	| "movie"
	| "series"
	| "video_game"
	| "show_for_kids"
	| "animated"
	| "live_action"
	| "comics";
export type TrackDifficulty = 1 | 2 | 3 | 5 | 8 | 13 | 21;
export type TrackAudioLengthInSeconds = 30 | 45 | 60;

export const trackExternalServices: Record<TrackExternalService, string> = {
	imdb: "IMDb",
	thetvdb: "TheTVDB",
	themoviedb: "TMDB",
};
export const trackTags: Record<TrackTag, string> = {
	movie: "Movie",
	series: "Series",
	video_game: "Video Game",
	show_for_kids: "Show For Kids",
	animated: "Animated",
	live_action: "Live Action",
	comics: "Comics",
};
export const trackDifficulties: Record<TrackDifficulty, string> = {
	1: "1 point",
	2: "2 points",
	3: "3 points",
	5: "5 points",
	8: "8 points",
	13: "13 points",
	21: "21 points",
};
export const trackAudioLengths: Record<TrackAudioLengthInSeconds, string> = {
	30: "30 seconds",
	45: "45 seconds",
	60: "60 seconds",
};
