import {
	Badge,
	Button,
	Chip,
	Dialog,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	IExternalId,
	ITrack,
	ITrackAudioClip,
	trackExternalServices,
	trackTags,
} from "../models/track";
import { getAudio, getTracks } from "../utils/tracks-provider";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { toMinutesAndSeconds } from "../utils/helpers";


const Tracks = () => {
	const [tracks, setTracks] = useState<ITrack[]>([]);

	const [audio, setAudio] = useState<HTMLAudioElement>();
	const [audioTimeout, setAudioTimeout] =
		useState<ReturnType<typeof setTimeout>>();
	const [coverDialogOpen, setCoverDialogOpen] = useState(false);
	const [cover, setCover] = useState<string>();

	const fetchTracks = async () => {
		const fetchedTracks = await getTracks();
		setTracks(fetchedTracks);
	};

	useEffect(() => {
		fetchTracks();
	});

	const handleClipClick = async (track: ITrack, clip: ITrackAudioClip) => {
		if (audio) {
			audio.pause();
		}
		if (audioTimeout) {
			clearTimeout(audioTimeout);
		}

		const newAudio = await getAudio(track.audioUrl);
		newAudio.currentTime = clip.audioStartInSeconds;
		newAudio.play();
		const newAudioTimeout = setTimeout(() => {
			newAudio.pause();
		}, clip.audioLengthInSeconds * 1000);
		setAudio(newAudio);
		setAudioTimeout(newAudioTimeout);
	};

	const handleExternalIdClick = (exId: IExternalId) => {
		let url = "";
		switch (exId.service) {
			case "imdb":
				url = `https://www.imdb.com/title/${exId.id}`;
				break;
			case "thetvdb":
				url = `https://thetvdb.com/series/${exId.id}`;
				break;
			case "themoviedb":
				url = `https://www.themoviedb.org/movie/${exId.id}`;
				break;
			default:
				url = "";
				break;
		}
		window.open(url, "_blank");
	};

	const handleViewCoverClick = (coverUrl?: string) => {
		setCover(coverUrl);
		setCoverDialogOpen(true);
	};

	return (
		<>
			<h1>Tracks</h1>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Title</TableCell>
						<TableCell>Clips</TableCell>
						<TableCell>Poster</TableCell>
						<TableCell>Difficulty</TableCell>
						<TableCell>Tags</TableCell>
						<TableCell>Debut Year</TableCell>
						<TableCell>External IDs</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tracks.map((track, idx) => (
						<TableRow key={idx}>
							<TableCell>
								<b>{track.title}</b>
							</TableCell>
							<TableCell>
								{track.audioClips.map((clip, clipIdx) => (
									<Badge
										color="info"
										badgeContent={toMinutesAndSeconds(clip.audioStartInSeconds)}
										key={`${idx}${clipIdx}`}
										sx={{mr: 2}}
									>
										<Chip
											onClick={() =>
												handleClipClick(track, clip)
											}
											icon={<PlayCircleIcon />}
											label={
												<b>
													{toMinutesAndSeconds(clip.audioLengthInSeconds)}
												</b>
											}
											sx={{cursor: "pointer" }}
											color="success"
										/>
									</Badge>
								))}
							</TableCell>
							<TableCell>
								{track.coverUrl && (
									<Button
										onClick={() =>
											handleViewCoverClick(track.coverUrl)
										}
									>
										View cover
									</Button>
								)}
							</TableCell>
							<TableCell>{track.difficulty}</TableCell>
							<TableCell>
								{track.tags?.map((trackTag, trackIdx) => (
									<Chip
										label={trackTags[trackTag]}
										key={`${idx}${trackIdx}`}
										sx={{ mx: 0.5 }}
									/>
								))}
							</TableCell>
							<TableCell>{track.debutYear}</TableCell>
							<TableCell>
								{track.externalIds?.map((exId, exIdIdx) => (
									<Chip
										onClick={() =>
											handleExternalIdClick(exId)
										}
										icon={<OpenInNewIcon />}
										key={`${idx}${exIdIdx}`}
										label={
											trackExternalServices[exId.service]
										}
										sx={{ mx: 0.5, cursor: "pointer" }}
									/>
								))}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Dialog
				open={coverDialogOpen}
				onClose={() => setCoverDialogOpen(false)}
			>
				<img src={cover} alt="cover" />
			</Dialog>
		</>
	);
};
export default Tracks;
