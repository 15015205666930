import React, { useEffect, useState } from "react";
import DevPanel from "../components/DevPanel";
import GameGetParams from "../components/GameGetParams";
import GameScoreboard from "../components/GameScoreboard";
import GameSplash from "../components/GameSplash";
import LoopAnswer from "../components/LoopAnswer";
import LoopCountdown from "../components/LoopCountdown";
import LoopError from "../components/LoopError";
import LoopGetParams from "../components/LoopGetParams";
import LoopLoading from "../components/LoopLoading";
import LoopPlayReady from "../components/LoopPlayReady";
import { IGameState } from "../models/game-state";
import GameStateManager from "../utils/game-state-manager";

const Home = () => {
	const [gameState, setGameState] = useState<IGameState | null>(null);

	useEffect(() => {
		GameStateManager.instance.onGameStateUpdated = (gameState) => {
			setGameState(gameState);
		};
		GameStateManager.instance.initGame();
	}, []);

	return (
		<>
			{process.env.NODE_ENV === "development" && gameState && (
				<DevPanel gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "game_splash" && (
				<GameSplash gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "game_get_params" && (
				<GameGetParams gameState={gameState} />
			)}
			{gameState &&
				(gameState.gameScene === "game_scoreboard" ||
					gameState.gameScene === "loop_get_params" ||
					gameState.gameScene === "loop_loading" ||
					gameState.gameScene === "loop_play_ready" ||
					gameState.gameScene === "loop_countdown" ||
					gameState.gameScene === "loop_answer" ||
					gameState.gameScene === "loop_error") && (
					<GameScoreboard gameState={gameState} />
				)}
			{gameState && gameState.gameScene === "loop_get_params" && (
				<LoopGetParams gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "loop_loading" && (
				<LoopLoading gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "loop_play_ready" && (
				<LoopPlayReady gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "loop_countdown" && (
				<LoopCountdown gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "loop_answer" && (
				<LoopAnswer gameState={gameState} />
			)}
			{gameState && gameState.gameScene === "loop_error" && (
				<LoopError gameState={gameState} />
			)}
		</>
	);
};
export default Home;
