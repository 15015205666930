import {
	TrackAudioLengthInSeconds,
	TrackDifficulty,
	ITrack,
	TrackTag,
	IUnrevealedTrack,
} from "./track";

export interface IGameState {
	gameParameters: IGameParameters;
	gameScene: GameScene;
	unrevealedTracks: IUnrevealedTrack[];
	currentLoop: ICurrentLoop;
	loopHistory: ILoopHistory[];
}

export interface IGameParameters {
	gameMode: GameMode;
	trackTags: TrackTag[];
	minTrackDifficulty: TrackDifficulty;
	maxTrackDifficulty: TrackDifficulty;
	audioClipLength: TrackAudioLengthInSeconds;
	additionalCountdown: AdditionalCountdownInSeconds;
	players: IPlayer[];
}

export interface IPlayer {
	playerGuid: string;
	name: string;
	colour: PlayerColour;
}

export interface ICurrentLoop {
	playerGuid?: string;
	track?: ITrack;
	outcome?: LoopOutcome;
	pointsAchieved?: number;
	audioPlaybackRemaining?: number;
	countdownRemaining?: number;
}

export interface ILoopHistory {
	playerGuid: string;
	trackGuid: string;
	outcome: LoopOutcome;
	pointsAchieved: number;
}

export type GameMode = "3_1" | "6_3" | "9_3" | "12_4" | "16_4";
export type GameTrackCount = 9 | 24 | 48 | 96;
export type GameScene =
	| "game_splash"
	| "game_get_params"
	| "game_scoreboard"
	| "loop_get_params"
	| "loop_loading"
	| "loop_play_ready"
	| "loop_countdown"
	| "loop_answer"
	| "loop_error";
export type AdditionalCountdownInSeconds = 0 | 10 | 15 | 30 | 45 | 60;
export type PlayerColour = "pink" | "lightBlue" | "lightGreen" | "yellow";
export type LoopOutcome = "correct" | "incorrect";

export const gameModes: Record<GameMode, string> = {
	"3_1": "3 rounds",
	"6_3": "6 rounds, 3x multiplier",
	"9_3": "9 rounds, 3x multiplier",
	"12_4": "12 rounds, 4x multiplier",
	"16_4": "16 rounds, 4x multiplier",
};
export const gameTracksCounts: Record<GameTrackCount, string> = {
	9: "9 (3 columns by 3 rows)",
	24: "24 (4 columns by 6 rows)",
	48: "48 (6 columns by 8 rows)",
	96: "96 (8 columns by 12 rows)",
};
export const gameArea: Record<GameTrackCount, { x: number; y: number }> = {
	9: { x: 3, y: 3 },
	24: { x: 4, y: 6 },
	48: { x: 6, y: 8 },
	96: { x: 8, y: 12 },
};
export const countdownLengthsInSeconds: Record<
	AdditionalCountdownInSeconds,
	string
> = {
	0: "No additional time",
	10: "10 seconds",
	15: "15 seconds",
	30: "30 seconds",
	45: "45 seconds",
	60: "60 seconds",
};
export const playerColours: Record<PlayerColour, string> = {
	pink: "Pink",
	lightBlue: "Light Blue",
	lightGreen: "Light Green",
	yellow: "Yellow",
};
export const gameModesRoundsMultipliers: Record<GameMode, number[]> = {
	"3_1": [1, 1, 1],
	"6_3": [1, 1, 2, 2, 3, 3],
	"9_3": [1, 1, 1, 2, 2, 2, 3, 3, 3],
	"12_4": [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4],
	"16_4": [1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4],
};
