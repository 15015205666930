import { database } from "../database";
import {
	AdditionalCountdownInSeconds,
	GameMode,
	gameModesRoundsMultipliers,
	GameScene,
	ICurrentLoop,
	IGameParameters,
	IGameState,
	ILoopHistory,
	IPlayer,
	LoopOutcome,
} from "../models/game-state";
import {
	IUnrevealedTrack,
	TrackAudioLengthInSeconds,
	TrackDifficulty,
	TrackTag,
} from "../models/track";
import { getAudio, getTrack, pickTracksForGame } from "./tracks-provider";

const sceneSwitchingRules: Record<GameScene, GameScene[]> = {
	game_splash: [],
	game_get_params: ["game_splash"],
	game_scoreboard: ["game_get_params", "loop_answer"],
	loop_get_params: ["game_scoreboard"],
	loop_loading: ["loop_get_params"],
	loop_play_ready: ["loop_loading"],
	loop_countdown: ["loop_play_ready"],
	loop_answer: ["loop_countdown"],
	loop_error: [],
};

export default class GameStateManager {
	// Singleton
	private static _instance: GameStateManager;

	constructor() {
		const defaultParameters = database.defaultSettings.gameParameters;
		this._gameMode = defaultParameters.gameMode;
		this._trackTags = defaultParameters.trackTags;
		this._minTrackDifficulty = defaultParameters.minTrackDifficulty;
		this._maxTrackDifficulty = defaultParameters.maxTrackDifficulty;
		this._audioClipLength = defaultParameters.audioClipLength;
		this._additionalCountdown = defaultParameters.additionalCountdown;
		this._players = defaultParameters.players;
	}

	public static get instance() {
		if (!GameStateManager._instance) {
			GameStateManager._instance = new GameStateManager();
		}
		return GameStateManager._instance;
	}

	public onGameStateUpdated?: (state: IGameState) => void;

	// Game parameters
	private _gameMode: GameMode;
	private _trackTags: TrackTag[];
	private _minTrackDifficulty: TrackDifficulty;
	private _maxTrackDifficulty: TrackDifficulty;
	private _audioClipLength: TrackAudioLengthInSeconds;
	private _additionalCountdown: AdditionalCountdownInSeconds;
	private _players: IPlayer[];

	// Game scene
	private _gameScene: GameScene = "game_splash";
	public get gameScene() {
		return this._gameScene;
	}

	private _unrevealedTracks: IUnrevealedTrack[] = [];
	public get unrevealedTracks() {
		return this._unrevealedTracks;
	}

	private _currentLoop: ICurrentLoop = {};
	public get currentLoop() {
		return this._currentLoop;
	}

	private get previousLoop() {
		if (this._loopHistory.length === 0) {
			return undefined;
		}
		return this._loopHistory[this._loopHistory.length - 1];
	}

	private _loopHistory: ILoopHistory[] = [];
	public get loopHistory() {
		return this._loopHistory;
	}

	public get currentRoundIdx() {
		return Math.floor(this._loopHistory.length / this._players.length);
	}
	public get totalRounds() {
		return gameModesRoundsMultipliers[this._gameMode].length;
	}
	public get currentMultiplier() {
		return gameModesRoundsMultipliers[this._gameMode][this.currentRoundIdx];
	}
	public get currentPlayer() {
		return this._players.find(
			(p) => p.playerGuid === this._currentLoop.playerGuid
		);
	}

	private _audio?: HTMLAudioElement;
	private _renderTimeDelta = 0;

	/* Public */
	// call on start of the app or reset
	public initGame = () => {
		this.clearGameState();
		this.switchGameScene("game_splash");
	};

	// call on "start game" button click
	public startGame = () => {
		this.switchGameScene("game_get_params");
	};

	// call when all game parameters are set
	public inputGameParameters = async (
		gameParameters: Partial<IGameParameters>
	) => {
		this.setGameParameters(gameParameters);
		this._unrevealedTracks = await pickTracksForGame(
			this._trackTags,
			this._audioClipLength,
			this._minTrackDifficulty,
			this._maxTrackDifficulty
		);
		this.switchGameScene("game_scoreboard");
	};

	public initLoop = () => {
		const player = this.getNextLoopPlayer();

		this._currentLoop = {
			playerGuid: player.playerGuid,
			audioPlaybackRemaining: this._audioClipLength,
			countdownRemaining:
				this._audioClipLength + this._additionalCountdown,
		};
		this.switchGameScene("loop_get_params");
	};

	public inputLoopParameters = async (trackGuid: string) => {
		this.switchGameScene("loop_loading");
		try {
			this._currentLoop.track = await getTrack(trackGuid);
			this._audio = await getAudio(this._currentLoop.track.audioUrl);
		} catch {
			this.switchGameScene("loop_error");
			return;
		}
		this.switchGameScene("loop_play_ready");
	};

	public startLoopCountdown = async () => {
		this.switchGameScene("loop_countdown");
		const clip = this._currentLoop.track?.audioClips.find(
			(c) => c.audioLengthInSeconds === this._audioClipLength
		);

		if (!clip || !this._audio) {
			this.switchGameScene("loop_error");
			return;
		}

		this._audio.onended = () => this.play(clip.audioStartInSeconds);
		this.play(clip.audioStartInSeconds);

		this._currentLoop.countdownRemaining =
			this._audioClipLength + this._additionalCountdown;
		this._currentLoop.audioPlaybackRemaining = this._audioClipLength;

		this._renderTimeDelta = performance.now();
		this.countdownRenderLoop(this._renderTimeDelta);
	};

	private async play(startInSeconds: number) {
		if (!this._audio) {
			return;
		}
		this._audio.currentTime = startInSeconds;
		await this._audio.play();
	}

	public showLoopAnswer = () => {
		this._audio?.pause();
		this.switchGameScene("loop_answer");
	};

	public inputScore = (outcome: LoopOutcome) => {
		this._currentLoop.outcome = outcome;
		if (outcome === "correct" && this._currentLoop.track?.difficulty) {
			this._currentLoop.pointsAchieved =
				this._currentLoop.track?.difficulty * this.currentMultiplier;
		} else {
			this._currentLoop.pointsAchieved = 0;
		}

		const loopHistory: ILoopHistory = {
			playerGuid: this._currentLoop.playerGuid as string,
			trackGuid: this._currentLoop.track?.trackGuid as string,
			outcome,
			pointsAchieved: this._currentLoop.pointsAchieved as number,
		};
		this._loopHistory.push(loopHistory);
		this.switchGameScene("game_scoreboard");
	};

	/* Private */

	private countdownRenderLoop = (t: number) => {
		if (this.gameScene !== "loop_countdown") {
			return;
		}
		const timeSpentInSeconds = (t - this._renderTimeDelta) / 1000;
		this._currentLoop.countdownRemaining =
			this._audioClipLength +
			this._additionalCountdown -
			timeSpentInSeconds;
		if (this._currentLoop.countdownRemaining < 0) {
			this._currentLoop.countdownRemaining = 0;
		}

		this._currentLoop.audioPlaybackRemaining =
			this._audioClipLength - timeSpentInSeconds;
		if (this._currentLoop.audioPlaybackRemaining < 0) {
			this._currentLoop.audioPlaybackRemaining = 0;
		}
		if (this._currentLoop.audioPlaybackRemaining === 0) {
			this._audio?.pause();
		}

		this.updateGameState();
		if (this._currentLoop.countdownRemaining > 0) {
			requestAnimationFrame(this.countdownRenderLoop);
		} else {
			this.showLoopAnswer();
		}
	};

	private getNextLoopPlayer = () => {
		// If there is no loop history, return first player
		if (!this.previousLoop) {
			return this._players[0];
		}
		// Get find last player from history and pick next one
		const previousPlayerGuid = this.previousLoop.playerGuid;
		const previousPlayerIdx = this._players.findIndex(
			(p) => p.playerGuid === previousPlayerGuid
		);
		const nextPlayerIdx = (previousPlayerIdx + 1) % this._players.length;
		return this._players[nextPlayerIdx];
	};

	private switchGameScene = (gameScene: GameScene) => {
		this.assertGameScene(...sceneSwitchingRules[gameScene]);
		this._gameScene = gameScene;
		this.updateGameState();
	};

	private updateGameState = () => {
		const gameState: IGameState = {
			gameParameters: {
				gameMode: this._gameMode,
				trackTags: this._trackTags,
				minTrackDifficulty: this._minTrackDifficulty,
				maxTrackDifficulty: this._maxTrackDifficulty,
				audioClipLength: this._audioClipLength,
				additionalCountdown: this._additionalCountdown,
				players: this._players,
			},
			gameScene: this._gameScene,
			unrevealedTracks: this._unrevealedTracks,
			currentLoop: this._currentLoop,
			loopHistory: this._loopHistory,
		};

		this.onGameStateUpdated && this.onGameStateUpdated(gameState);
	};

	private setGameParameters = (gameParameters?: Partial<IGameParameters>) => {
		const defaultParameters = database.defaultSettings.gameParameters;
		this._gameMode = gameParameters?.gameMode ?? defaultParameters.gameMode;
		this._trackTags =
			gameParameters?.trackTags ?? defaultParameters.trackTags;
		this._minTrackDifficulty =
			gameParameters?.minTrackDifficulty ??
			defaultParameters.minTrackDifficulty;
		this._maxTrackDifficulty =
			gameParameters?.maxTrackDifficulty ??
			defaultParameters.maxTrackDifficulty;
		this._audioClipLength =
			gameParameters?.audioClipLength ??
			defaultParameters.audioClipLength;
		this._additionalCountdown =
			gameParameters?.additionalCountdown ??
			defaultParameters.additionalCountdown;
		this._players = gameParameters?.players ?? defaultParameters.players;
	};

	private clearGameState = () => {
		this.setGameParameters();
		// TODO: reset other state variables
	};

	private assertGameScene(...gameScenes: GameScene[]) {
		if (
			gameScenes.length !== 0 &&
			gameScenes.every((g) => g !== this._gameScene)
		) {
			throw new Error(
				`Expected game scene to be ${gameScenes
					.map((g) => `"${g}"`)
					.join(" or ")} but was "${this.gameScene}"`
			);
		}
	}
}
