import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Stack,
	Typography,
} from "@mui/material";
import React from "react";
import { IGameState, IPlayer, LoopOutcome } from "../models/game-state";
import { TrackTag } from "../models/track";
import GameStateManager from "../utils/game-state-manager";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface DevPanelProps {
	gameState: IGameState;
}

const DevPanel = (props: DevPanelProps) => {
	const { gameState } = props;

	const handleInitGameClick = () => {
		console.log("Init Game");
		GameStateManager.instance.initGame();
	};

	const handleStartGameClick = () => {
		console.log("Start Game");
		GameStateManager.instance.startGame();
	};

	const handleInputGameParametersClick = () => {
		console.log("Input Game Parameters");
		const trackTags: TrackTag[] = ["movie"];
		const players: IPlayer[] = [
			{
				playerGuid: "d8e02370-301c-4f35-831a-2e95bc341491",
				name: "Player 1",
				colour: "pink",
			},
			{
				playerGuid: "a266e09e-b71f-4ea9-b2bf-c7b52b0932c5",
				name: "Player 2",
				colour: "lightBlue",
			},
		];
		GameStateManager.instance.inputGameParameters({ trackTags, players });
	};

	const handleInitLoopClick = () => {
		console.log("Init Loop");
		GameStateManager.instance.initLoop();
	};

	const handleInputLoopParametersClick = (trackGuid: string) => {
		console.log("Input Loop Parameters");
		GameStateManager.instance.inputLoopParameters(trackGuid);
	};

	const handleStartLoopCountdownClick = () => {
		console.log("Start Loop Countdown");
		GameStateManager.instance.startLoopCountdown();
	};

	const handleShowLoopAnswerClick = () => {
		console.log("Show Loop Answer");
		GameStateManager.instance.showLoopAnswer();
	};

	const handleInputScoreClick = (outcome: LoopOutcome) => {
		console.log("Input score");
		GameStateManager.instance.inputScore(outcome);
	};

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>Dev panel</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack spacing={1} m={2} direction="row">
					<Button
						variant="contained"
						color="warning"
						onClick={handleInitGameClick}
					>
						Init Game
					</Button>
					<Button
						variant="contained"
						color="info"
						onClick={handleStartGameClick}
					>
						Start Game
					</Button>
					<Button
						variant="contained"
						color="info"
						onClick={handleInputGameParametersClick}
					>
						Input Parameters
					</Button>
				</Stack>
				<Stack spacing={1} m={2} direction="row">
					<Button
						variant="contained"
						color="success"
						onClick={handleInitLoopClick}
					>
						Init Loop
					</Button>
				</Stack>
				<Stack spacing={1} m={2} direction="row">
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"b1f384b7-3b5f-4621-84f0-c19176008288"
							)
						}
					>
						01
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"58be094d-04f3-4345-8273-60beace6e42c"
							)
						}
					>
						02
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"e0459ea2-9d70-4951-a3c6-12e83d5ac685"
							)
						}
					>
						03
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"b0777ed1-f882-4059-b297-76cb286dee2f"
							)
						}
					>
						04
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"7b72b239-ff69-4625-a332-2bec46d04a88"
							)
						}
					>
						05
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"950dc405-6fa0-4cb2-ae13-27d2ce04a402"
							)
						}
					>
						06
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"31b63676-fa5f-4414-b8ff-c0bf1eb44712"
							)
						}
					>
						07
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"50582fd7-7768-45bd-a455-4c4f9b331df5"
							)
						}
					>
						08
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() =>
							handleInputLoopParametersClick(
								"4f3cbf72-1693-49ec-b6d2-87d2c854ea69"
							)
						}
					>
						09
					</Button>

					<Button
						variant="contained"
						color="warning"
						onClick={() =>
							handleInputLoopParametersClick("1234556")
						}
					>
						Invalid Input Loop Parameters
					</Button>
				</Stack>
				<Stack spacing={1} m={2} direction="row">
					<Button
						variant="contained"
						color="success"
						onClick={handleStartLoopCountdownClick}
					>
						Start Loop Countdown
					</Button>
				</Stack>
				<Stack spacing={1} m={2} direction="row">
					<Button
						variant="contained"
						color="success"
						onClick={handleShowLoopAnswerClick}
					>
						Show Loop Answer
					</Button>
				</Stack>
				<Stack spacing={1} m={2} direction="row">
					<Button
						variant="contained"
						color="success"
						onClick={() => handleInputScoreClick("correct")}
					>
						Correct
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() => handleInputScoreClick("incorrect")}
					>
						Incorrect
					</Button>
				</Stack>
				{
					<ul>
						<li>
							<b>Game Scene:</b>{" "}
							{JSON.stringify(gameState?.gameScene)}
						</li>
						<li>
							<b>Game Parameters:</b>
							{gameState?.gameParameters && (
								<ul>
									<li>
										<b>Audio Clip Length:</b>{" "}
										{JSON.stringify(
											gameState?.gameParameters
												.audioClipLength
										)}
									</li>
									<li>
										<b>Countdown Length:</b>{" "}
										{JSON.stringify(
											gameState?.gameParameters
												.additionalCountdown
										)}
									</li>
									<li>
										<b>Difficulty:</b>{" "}
										{JSON.stringify(
											gameState?.gameParameters
												.minTrackDifficulty
										)}{" "}
										to{" "}
										{JSON.stringify(
											gameState?.gameParameters
												.maxTrackDifficulty
										)}
									</li>
									<li>
										<b>Track Tags:</b>{" "}
										{JSON.stringify(
											gameState?.gameParameters.trackTags
										)}
									</li>
									<li>
										<b>
											Players:{" "}
											{
												gameState?.gameParameters
													.players.length
											}
										</b>
										<ul>
											{gameState?.gameParameters.players.map(
												(p, idx) => (
													<li key={idx}>
														{JSON.stringify(p)}
													</li>
												)
											)}
										</ul>
									</li>
								</ul>
							)}
						</li>
						<li>
							<b>
								Unrevealed Tracks:{" "}
								{gameState?.unrevealedTracks.length}
							</b>
							<ul>
								{gameState?.unrevealedTracks.map((u, idx) => (
									<li key={idx}>{JSON.stringify(u)}</li>
								))}
							</ul>
						</li>
						<li>
							<b>Current Loop:</b>{" "}
							{JSON.stringify(gameState?.currentLoop)}
						</li>
						<li>
							<b>Loop History:</b>
							<ul>
								{gameState?.loopHistory.map((l, idx) => (
									<li key={idx}>{JSON.stringify(l)}</li>
								))}
							</ul>
						</li>
					</ul>
				}
			</AccordionDetails>
		</Accordion>
	);
};
export default DevPanel;
