import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IGameState } from "../models/game-state";
import { IUnrevealedTrack } from "../models/track";
import GameStateManager from "../utils/game-state-manager";

interface LoopGetParams2Props {
	gameState: IGameState;
}

const LoopGetParams = (props: LoopGetParams2Props) => {
	const { gameState } = props;

	const [availableTracks, setAvailableTracks] = useState<IUnrevealedTrack[]>(
		[]
	);
	const [difficultyCount, setDifficultyCount] = useState<number[]>([]);

	useEffect(() => {
		const newUnrevealedTracks = [...gameState.unrevealedTracks].filter(
			(ut) =>
				gameState.loopHistory.every(
					(lh) => lh.trackGuid !== ut.trackGuid
				)
		);
		const dc = [...difficultyCount];
		newUnrevealedTracks.forEach((t) => {
			if (!t.difficulty) {
				return;
			}
			if (!dc[t.difficulty]) {
				dc[t.difficulty] = 0;
			}
			dc[t.difficulty]++;
		});
		setAvailableTracks(newUnrevealedTracks);
		setDifficultyCount(dc);
	}, [gameState.unrevealedTracks, gameState.loopHistory]);

	const handleDifficultyClick = (difficulty: number) => {
		const track = availableTracks.find(
			(ut) => ut.difficulty === difficulty
		);
		if (!track) {
			return;
		}
		GameStateManager.instance.inputLoopParameters(track.trackGuid);
	};

	return (
		<Dialog open fullWidth maxWidth="lg">
			<DialogTitle
				sx={{
					backgroundColor:
						GameStateManager.instance.currentPlayer?.colour,
				}}
			>
				{GameStateManager.instance.currentPlayer?.name}, please pick
				difficulty (multiplier: x
				{GameStateManager.instance.currentMultiplier})
			</DialogTitle>
			<DialogContent>
				<Table sx={{ my: 4 }} size="small">
					<TableBody>
						<TableRow>
							{difficultyCount.map((dc, idx) => (
								<TableCell key={idx} align="center">
									<Button
										size="large"
										variant="text"
										onClick={() =>
											handleDifficultyClick(idx)
										}
										style={{ fontSize: 36 }}
									>
										{idx}
									</Button>
									<br />
									{dc} remaining
								</TableCell>
							))}
						</TableRow>
					</TableBody>
				</Table>
			</DialogContent>
		</Dialog>
	);
};
export default LoopGetParams;
