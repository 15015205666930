import { database } from "../database";
import {
	IUnrevealedTrack,
	TrackAudioLengthInSeconds,
	TrackDifficulty,
	TrackTag,
} from "../models/track";
import { promiseTimeout } from "./helpers";

export const getTrack = async (trackGuid: string) => {
	const track = database.tracks.find((s) => s.trackGuid === trackGuid);
	await promiseTimeout(500);
	if (!track) {
		throw new Error("404");
	}
	return track;
};

export const getTracks = async () => {
	await promiseTimeout(100);
	return database.tracks;
};

export const pickTracksForGame = async (
	trackTags: TrackTag[],
	audioLengthInSeconds: TrackAudioLengthInSeconds,
	minTrackDifficulty: TrackDifficulty,
	maxTrackDifficulty: TrackDifficulty
) => {
	await promiseTimeout(500);
	return [...database.tracks]
		.filter(
			(t) =>
				t.difficulty &&
				t.difficulty >= minTrackDifficulty &&
				t.difficulty <= maxTrackDifficulty &&
				t.audioClips.some(
					(a) => a.audioLengthInSeconds === audioLengthInSeconds
				) &&
				trackTags.every((tag) => t.tags?.some((tt) => tt === tag))
		)
		.sort(() => Math.random() - 0.5)
		.map(
			(t) =>
				({
					trackGuid: t.trackGuid,
					difficulty: t.difficulty,
				} as IUnrevealedTrack)
		);
};

export const getAudio = async (audioUrl: string) => {
	return new Promise<HTMLAudioElement>((resolve) => {
		const audio = new Audio(audioUrl);
		audio.addEventListener("canplaythrough", () => {
			resolve(audio);
		});
	});
};
