import React from "react";
import { IGameState } from "../models/game-state";

interface LoopErrorProps {
	gameState: IGameState;
}

const LoopError = (props: LoopErrorProps) => {
	return <p>Loop Error</p>;
};
export default LoopError;
